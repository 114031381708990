const API_HOST = `${window.baseURL}/dealer_admin_youche`;
const API_HOST_SYSTEM = `${window.baseURL}/part_dealer_admin`;
const API_HOST_PART = `${window.baseURL}/part_dealer_admin`;

const api = {
  config: {
    limit: 15,
    halfLimit: 10,
    skip: 0,
  },

  system: {
    getVerifyCode() {
      return `${API_HOST_SYSTEM}/user/get-code`;
    },
    login() {
      return `${API_HOST_SYSTEM}/user/login`;
    },
    logout() {
      return `${API_HOST_SYSTEM}/user/logout`;
    },
    userInfo() {
      return `${API_HOST_SYSTEM}/user/info`;
    },
    refressLogin() {
      return `${API_HOST_SYSTEM}/user/refress-login`;
    },
  },

  user: {
    list(condition) {
      const { page = 1, department = '', status = '-10', key = '' } = condition;
      return `${API_HOST_SYSTEM}/user/user-list?skip=${((page - 1) || 0) *
      api.config.limit}&limit=${api.config.limit}&department=${department}&status=${status}&keyword=${key}`;
    },
    listAll() {
      return `${API_HOST_SYSTEM}/user/user-list?skip=0&limit=-1&department=&role=&status=0`;
    },
    create() {
      return `${API_HOST_SYSTEM}/user/create`;
    },
    edit() {
      return `${API_HOST_SYSTEM}/user/edit`;
    },
    detail(userId) {
      return `${API_HOST_SYSTEM}/user/detail?user_id=${userId}`;
    },
    departmentRoles(department) {
      return `${API_HOST_SYSTEM}/user/department-roles?department=${department}`;
    },
    fire() {
      return `${API_HOST_SYSTEM}/user/fire`;
    },
    departmentRolesAll() {
      return `${API_HOST_SYSTEM}/user/all-department-roles`;
    },
    userTrack(date, userId = '') {
      return `${API_HOST_SYSTEM}/user/user-track-list?date=${date}&user_id=${userId}&type=0`;
    },
    userTrackYingyan(date, userId = '') {
      return `${API_HOST_SYSTEM}/user/user-yingyan-track-list?user_id=${userId}&date=${date}`;
    },
    getAll(condition) {
      const { department = '', key = '' } = condition;
      return `${API_HOST_SYSTEM}/user/user-list?skip=0&limit=-1&department=${department}&keyword=${key}&role=&status=0`;
    },
    batchEdit() {
      return `${API_HOST_SYSTEM}/user/batch-transfer-company`;
    },

    // 1.1 公司的全部权限(菜单项)
    getCompanyPermissions() {
      return `${API_HOST_SYSTEM}/user/auth-item-list`;
    },
    // 1.2 普通用户权限(菜单项)
    getCommonUserPermissions() {
      return `${API_HOST_SYSTEM}/user/self-auth-item-list`;
    },
    checkPermission(path) {
      return `${API_HOST_SYSTEM}/user/check-auth?path=${path}`;
    },

    // 员工的权限(人事管理，新增编辑时使用)
    getUserPermissions(userId) {
      return `${API_HOST_SYSTEM}/user/user-auth-item-list?user_id=${userId}`;
    },

    // 员工所属角色的全部权限
    getRolePermissions(roleId) {
      return `${API_HOST_SYSTEM}/user/auth-role-item-list?role=${roleId}`;
    },

    editPermission() {
      return `${API_HOST_SYSTEM}/user/edit-auth`;
    },
  },

  resource: {
    getAll() {
      return `${API_HOST}/resource/list?skip=0&limit=-1`;
    },
  },

  material: {
    getAllByResourceId(resourceId) {
      return `${API_HOST}/material/list?resource_id=${resourceId}skip=0&limit=-1`;
    },
  },

  /**
   * 配件相关: 配件，商品，活动
   * 1. part
   * 2. goods
   * 3. activity
   */

  partsSale: {
    push: {
      list(condition) {
        const { page = 1, key = '', status = '', fromType = '0' } = condition;
        return `${API_HOST_PART}/push/push-plan-list?key=${key}&status=${status}&from_type=${fromType}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_HOST_PART}/push/create-push-plan`;
      },

      edit() {
        return `${API_HOST_PART}/push/edit-push-plan`;
      },

      pushDelete() {
        return `${API_HOST_PART}/push/delete-push-plan`;
      },
    },

    partBrand: {
      list(condition) {
        const { page = 1, key = '' } = condition;
        return `${API_HOST_PART}/part/part-brand-list?keyword=${key}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      listAll() {
        return `${API_HOST_PART}/part/part-brand-list?keyword=&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_HOST_PART}/part/part-brand-detail?brand_id=${id}`;
      },

      brandAndTypes() {
        return `${API_HOST_PART}/part/brand-and-part-type-list`;
      },
      updateVisible() {
        return `${API_HOST_PART}/part/update-visible-type`;
      },
      addAttendCompany() {
        return `${API_HOST_PART}/part/add-company-attend-brand`;
      },
      deleteAttendCompany() {
        return `${API_HOST_PART}/part/remove-company-attend-brand`;
      },
      attendCompanies(condition) {
        const { page = 1, partType = '', brandId = '' } = condition;
        return `${API_HOST_PART}/part/brand-company-attend-list?part_type=${partType}&brand_id=${brandId}&skip=${(page -
          1) * api.config.halfLimit}&limit=${api.config.halfLimit}`;
      },
      absenceCompanies(condition) {
        const { page = 1, key = '', partType = '', brandId = '' } = condition;
        return `${API_HOST_PART}/part/brand-company-not-attend-list?key=${key}&part_type=${partType}&brand_id=${brandId}&skip=${(page -
          1) * api.config.halfLimit}&limit=${api.config.halfLimit}`;
      },
    },

    directBrand: {
      list(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_PART}/part/direct-brand-list?skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      brandAndTypes() {
        return `${API_HOST_PART}/part/brand-and-part-type-list`;
      },
      detail(id) {
        return `${API_HOST_PART}/part/part-brand-detail?brand_id=${id}`;
      },
      partTypes(id) {
        return `${API_HOST_PART}/part/part-type-list-by-direct-brand?brand_id=${id}`;
      },
    },

    part: {
      list(condition) {
        const { page = 1, key = '', brandId = '', partType = '' } = condition;
        return `${API_HOST_PART}/part/part-list?keyword=${key}&brand_id=${brandId}&part_type=${partType}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      searchList(condition) {
        const { key = '', brandId = '', partType = '' } = condition;
        return `${API_HOST_PART}/part/part-list?keyword=${key}&part_type=&brand=${brandId}&scope=${partType}&skip=0&limit=-1`;
      },
      add() {
        return `${API_HOST_PART}/part/add-part`;
      },
      edit() {
        return `${API_HOST_PART}/part/edit-part`;
      },
      updateStatus() {
        return `${API_HOST_PART}/part/update-part-status`;
      },
      detail(id) {
        return `${API_HOST_PART}/part/part-detail?part_id=${id}`;
      },
      types() {
        return `${API_HOST_PART}/part/all-part-types`;
      },
    },

    // todo change goods to product
    goods: {
      list(condition) {
        const { page = 1, key = '', status = '' } = condition;
        return `${API_HOST_PART}/product/list?keyword=${key}&status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      detail(id) {
        return `${API_HOST_PART}/product/detail?product_id=${id}`;
      },
      add() {
        return `${API_HOST_PART}/product/create`;
      },
      edit() {
        return `${API_HOST_PART}/product/edit`;
      },
      editPic() {
        return `${API_HOST_PART}/product/edit-pic`;
      },
      updateStatus() {
        return `${API_HOST_PART}/product/update-status`;
      },
      updateIsHot() {
        return `${API_HOST_PART}/product/update-is-hot`;
      },

      updateSortOrder() {
        return `${API_HOST_PART}/product/update-sort-order`;
      },

      // 商品项目
      editItems() {
        return `${API_HOST_PART}/product/edit-item`;
      },
      addItems() {
        return `${API_HOST_PART}/product/create-item`;
      },
      itemList(condition) {
        const { productId = '', status = '', key = '', page = 1, limit } = condition;
        return `${API_HOST_PART}/product/item-list?product_id=${productId}&keyword=${key}&status=${status}&skip=${(page -
          1) * (limit || api.config.limit)}&limit=${limit || api.config.limit}`;
      },
      updateItemStatus() {
        return `${API_HOST_PART}/product/update-item-status`;
      },
      itemDetail(id, companyId = '') {
        return `${API_HOST_PART}/product/item-detail?product_item_id=${id}&company_id=${companyId}`;
      },

      additemUnit() {
        return `${API_HOST_PART}/product/add-item-unit`;
      },
      edititemUnit() {
        return `${API_HOST_PART}/product/edit-item-unit`;
      },

      updateItemSortOrder() {
        return `${API_HOST_PART}/product/update-item-sort-order`;
      },
    },

    brandIntention: {
      list(condition) {
        const { page, companyId = '', brandId = '', partType = '', level = '' } = condition;
        return `${API_HOST_PART}/company/company-brand-intention-list?company_id=${companyId}&brand_id=${brandId}&part_type=${partType}&level=${level}&skip=${(page -
          1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      export(condition) {
        const { companyId = '', brandId = '', partType = '', level = '' } = condition;
        return `${API_HOST_PART}/company/export-company-brand-intention?company_id=${companyId}&brand_id=${brandId}&part_type=${partType}&level=${level}&rtick=${new Date().getTime()}`;
      },
      reject() {
        return `${API_HOST_SYSTEM}/company/reject-company-brand-intention`;
      },
    },

    brandActivity: {
      list(condition) {
        const { page = 1, key = '' } = condition;
        return `${API_HOST_PART}/part/brand-activity-list?keyword=${key}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      getAll() {
        return `${API_HOST_PART}/part/brand-activity-list?keyword=&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_HOST_PART}/part/brand-activity-detail?activity_id=${id}`;
      },
      add() {
        return `${API_HOST_PART}/part/create-brand-activity`;
      },
      edit() {
        return `${API_HOST_PART}/part/edit-brand-activity`;
      },
      // activity_id, status: -1:下架  0：编辑中  1：上架
      updateStatus() {
        return `${API_HOST_PART}/part/update-brand-activity-status`;
      },
      deleteAttendLog() {
        return `${API_HOST_PART}/part/delete-brand-activity-log`;
      },
      attendLog(condition) {
        const { page = 1, activityId = '' } = condition;
        return `${API_HOST_PART}/part/brand-activity-log-list?activity_id=${activityId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      attendLogAuth() {
        return `${API_HOST_PART}/part/brand-activity-log-auth`;
      },
      attendLogDetail(condition) {
        const { logId, activityId } = condition;
        return `${API_HOST_PART}/part/brand-activity-log-detail?log_id=${logId}&activity_id=${activityId}`;
      },
    },

    activity: {
      list(condition) {
        const { page = 1, key = '', status = '-2' } = condition;
        return `${API_HOST_PART}/activity/list?keyword=${key}&status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      getAll() {
        return `${API_HOST_PART}/activity/list?keyword=&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_HOST_PART}/activity/detail?activity_id=${id}`;
      },
      add() {
        return `${API_HOST_PART}/activity/create`;
      },
      edit() {
        return `${API_HOST_PART}/activity/edit`;
      },
      editProduct() {
        return `${API_HOST_PART}/activity/edit-product`;
      },
      editRewardRule() {
        return `${API_HOST_PART}/activity/edit-reward-rule`;
      },
      updateStatus() {
        return `${API_HOST_PART}/activity/update-status`;
      },
      companyList(condition) {
        const { page = 1, activityId = '' } = condition;
        return `${API_HOST_PART}/activity/activity-company-list?activity_id=${activityId}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      getStatisticSummary(id) {
        return `${API_HOST_PART}/activity/statistic-summary?activity_id=${id}`;
      },

      getQRCode(id) {
        return `${API_HOST_PART}/activity/get-qrcode-pic-data?activity_id=${id}`;
      },

      listCompanyProductItem(id, productItemId) {
        return `${API_HOST_PART}/activity/list-by-company-product-item?company_id=${id}&product_item_id=${productItemId}`;
      },
    },

    order: {
      list(condition) {
        const { page = 1, status = '', payStatus = '', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', companyId = '', cityId = '', countryId = '', supplierUserId = '', limit } = condition;
        return `${API_HOST_PART}/order/list?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&pay_channel=${payChannel}&order_id=${orderId}&company_id=${companyId}&city_id=${cityId}&country_id=${countryId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&skip=${(page -
          1) * (limit || api.config.limit)}&limit=${limit || api.config.limit}`;
      },

      getAll(condition) {
        const { status = '', payStatus = '', isUnpay = '0', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', companyId = '', cityId = '', countryId = '', supplierUserId = '' } = condition;
        return `${API_HOST_PART}/order/list?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&pay_channel=${payChannel}&is_unpay=${isUnpay}&order_id=${orderId}&company_id=${companyId}&city_id=${cityId}&country_id=${countryId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&skip=0&limit=-1`;
      },

      export(condition) {
        const { status = '', isUnpay = '0', payStatus = '', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', companyId = '', cityId = '', countryId = '', supplierUserId = '' } = condition;
        return `${API_HOST_PART}/order/export-product-order?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&pay_channel=${payChannel}&is_unpay=${isUnpay}&order_id=${orderId}&company_id=${companyId}&city_id=${cityId}&country_id=${countryId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&rtick=${new Date().getTime()}`;
      },
      detail(id) {
        return `${API_HOST_PART}/order/detail?order_id=${id}`;
      },

      itemList(id) {
        return `${API_HOST_PART}/order/item-list?order_id=${id}`;
      },

      auth() {
        return `${API_HOST_PART}/order/auth-order`;
      },

      create() {
        return `${API_HOST_PART}/order/create`;
      },

      edit() {
        return `${API_HOST_PART}/order/edit`;
      },

      closeOrder() {
        return `${API_HOST_PART}/order/close-order`;
      },
    },
  },

  shuiDaoPurchase: {
    order: {
      list(condition) {
        const { page = 1, status = '', payStatus = '', limit } = condition;
        return `${API_HOST_PART}/shuidao-product/order-list?status=${status}&pay_status=${payStatus}&skip=${(page -
          1) * (limit || api.config.limit)}&limit=${limit || api.config.limit}`;
      },

      create() {
        return `${API_HOST_PART}/shuidao-product/create-order`;
      },

      edit() {
        return `${API_HOST_PART}/shuidao-product/edit-order`;
      },

      detail(id) {
        return `${API_HOST_PART}/shuidao-product/order-detail?order_id=${id}`;
      },

      itemList(orderId) {
        return `${API_HOST_PART}/shuidao-product/order-item-list?order_id=${orderId}`;
      },

      close() {
        return `${API_HOST_PART}/shuidao-product/close-order`;
      },

      confirmOrder() {
        return `${API_HOST_PART}/shuidao-product/confirm-order`;
      },

      receiveOrder() {
        return `${API_HOST_PART}/shuidao-product/receive-order`;
      },
    },

    goods: {
      list(condition) {
        const { page = 1, key = '' } = condition;
        return `${API_HOST_PART}/shuidao-product/list?keyword=${key}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      itemList(condition) {
        const { key = '', page = 1, productId = '', limit } = condition;
        return `${API_HOST_PART}/shuidao-product/item-list?keyword=${key}&product_id=${productId}&skip=${(page -
          1) * (limit || api.config.limit)}&limit=${limit || api.config.limit}`;
      },
      itemDetail(id) {
        return `${API_HOST_PART}/shuidao-product/item-detail?product_item_id=${id}`;
      },
    },
  },

  finance: {
    pay: {
      list(condition) {
        const {
          page = 1,
          isTransferred = 0,
          payTimeStart,
          payTimeEnd,
          transferTimeStart,
          transferTimeEnd,
          payChannel,
          sdTransferId = '',
        } = condition;
        return `${API_HOST_PART}/order/pay-list?is_sd_transfer=${isTransferred}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&sd_transfer_time_start=${transferTimeStart}&sd_transfer_time_end=${transferTimeEnd}&pay_channel=${payChannel}&sd_transfer_id=${sdTransferId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
    },
    transfer: {
      list(condition) {
        const { page = 1, transferTimeStart, transferTimeEnd } = condition;
        return `${API_HOST_PART}/order/transfer-list?transfer_time_start=${transferTimeStart}&transfer_time_end=${transferTimeEnd}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
    },
    wallet: {
      info() {
        return `${API_HOST_SYSTEM}/wallet/wallet-info`;
      },
      chargeList(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_SYSTEM}/wallet/charge-list?skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      consumeList(condition) {
        const { page = 1, startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/wallet/consume-list?start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getChargeLevel() {
        return `${API_HOST_SYSTEM}/wallet/get-charge-level`;
      },

      getParams(condition) {
        const { chargeLevel = '', dealerId = '', userId = '' } = condition;
        return `${API_HOST_SYSTEM}/wallet/gen-charge-params?charge_level=${chargeLevel}&dealer_id=${dealerId}&user_id=${userId}`;
      },

      export(condition) {
        const { startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/wallet/export-consume-list?start_date=${startDate}&end_date=${endDate}&rtick=${new Date().getTime()}`;
      },
    },

    sysWallet: {
      info() {
        return `${API_HOST_SYSTEM}/prepay-wallet/wallet-info`;
      },
      chargeList(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_SYSTEM}/prepay-wallet/charge-list?skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      consumeList(condition) {
        const { page = 1, startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/prepay-wallet/consume-list?start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getChargeLevel() {
        return `${API_HOST_SYSTEM}/prepay-wallet/get-charge-level`;
      },

      getParams(condition) {
        const { chargeLevel = '', dealerId = '', userId = '' } = condition;
        return `${API_HOST_SYSTEM}/prepay-wallet/gen-charge-params?charge_level=${chargeLevel}&dealer_id=${dealerId}&user_id=${userId}`;
      },

      export(condition) {
        const { startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/prepay-wallet/export-consume-list?start_date=${startDate}&end_date=${endDate}&rtick=${new Date().getTime()}`;
      },
    },

    liveWallet: {
      chargeList(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_SYSTEM}/conference-wallet/charge-list?skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      consumeList(condition) {
        const { page = 1, type = 0, startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/conference-wallet/consume-list?type=${type}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      consumeListExport(condition) {
        const { startDate = '', endDate = '' } = condition;
        return `${API_HOST_SYSTEM}/conference-wallet/export-consume-list?start_date=${startDate}&end_date=${endDate}&rtick=${new Date().getTime()}`;
      },
      getChargeLevel() {
        return `${API_HOST_SYSTEM}/conference-wallet/get-charge-level`;
      },
    },

    paySummary() {
      return `${API_HOST_PART}/order/pay-summary`;
    },

    getProjectTypeList(type) {
      return `${API_HOST_PART}/financial/journal-sub-type-list?type=${type}`;
    },

    getDailyTypeList(type = '0', key = '', page = 1) {
      return `${API_HOST_PART}/financial/daily-sub-type-list?type=${type}&key=${key}&skip=${((page -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    getExpenseList(page, startTime, endTime, type, subType) {
      return `${API_HOST_PART}/financial/journal-list?skip=${((page - 1) || 0) *
      api.config.limit}&limit=${api.config.limit}&start_date=${startTime}&end_date=${endTime}&type=${type}&sub_type=${subType}`;
    },

    newExpenseType() {
      return `${API_HOST_PART}/financial/create-daily-sub-type`;
    },

    newDailyExpense() {
      return `${API_HOST_PART}/financial/create-daily`;
    },

    exportJournal(condition) {
      const { startTime, endTime, balancePaymentsType, projectType } = condition;
      return `${API_HOST_PART}/financial/export-journal-list?start_date=${startTime}&end_date=${endTime}&type=${balancePaymentsType}&sub_type=${projectType}&rtick=${new Date().getTime()}`;
    },

    productIncomeItemOrders(id) {
      return `${API_HOST_PART}/financial/product-income-item-list-by-order?order_id=${id}`;
    },

    onAccount: {
      list(condition) {
        const { page = 1, startTime = '', endTime = '', type = '', subType = '', companyId = '', cityId = '', countryId = '', supplierUserId = '' } = condition;
        return `${API_HOST_PART}/financial/on-account-order-list?supplier_user_id=${supplierUserId}&company_id=${companyId}&city_id=${cityId}&country_id=${countryId}&skip=${((page -
          1) || 0) *
        api.config.limit}&limit=${api.config.limit}&start_date=${startTime}&end_date=${endTime}&type=${type}&sub_type=${subType}`;
      },

      companyList(condition) {
        const { page = 1, startTime = '', endTime = '', type = '', subType = '' } = condition;
        return `${API_HOST_PART}/financial/on-account-company-list?skip=${((page - 1) || 0) *
        api.config.limit}&limit=${api.config.limit}&start_date=${startTime}&end_date=${endTime}&type=${type}&sub_type=${subType}`;
      },

      payIncome() {
        return `${API_HOST_PART}/financial/pay-product-income`;
      },

      create() {
        return `${API_HOST_PART}/financial/create-product-income`;
      },

      edit() {
        return `${API_HOST_PART}/financial/edit-product-income`;
      },

      detail(id) {
        return `${API_HOST_PART}/financial/product-income-detail?income_id=${id}`;
      },

      productIncomeList(condition) {
        const { companyId = '', status = '', page = 1, payChannel = '-1', payType = '-10' } = condition;
        return `${API_HOST_PART}/financial/product-income-list?company_id=${companyId}&pay_channel=${payChannel}&pay_type=${payType}&skip=${((page -
          1) || 0) *
        api.config.limit}&limit=${api.config.limit}&status=${status}`;
      },

      remove() {
        return `${API_HOST_PART}/financial/delete-product-income`;
      },

      auth() {
        return `${API_HOST_PART}/financial/auth-product-income`;
      },
    },
  },

  journal: {
    list(condition) {
      const { page = 1, templateId = '', companyId = '', userId = '', startDate = '', endDate = '' } = condition;
      return `${API_HOST_SYSTEM}/journal/journal-list?template_id=${templateId}&company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
        1) * api.config.limit}&limit=${api.config.limit}`;
    },
    export(condition) {
      const { page = 1, templateId = '', companyId = '', userId = '', startDate = '', endDate = '' } = condition;
      return `${API_HOST_SYSTEM}/journal/export-journal-list?template_id=${templateId}&company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
        1) * api.config.limit}&limit=${api.config.limit}&rtick=${new Date().getTime()}`;
    },
    listByCompanyId(page = 1, companyId = '') {
      return `${API_HOST_SYSTEM}/journal/company-journal-list?company_id=${companyId}&skip=${(page -
        1) * api.config.limit}&limit=${api.config.limit}`;
    },
    detailByCompanyId(id, companyId) {
      return `${API_HOST_SYSTEM}/journal/journal-detail?journal_id=${id}&company_id=${companyId}`;
    },

    template: {
      list(condition) {
        const { page = 1, status = '-2' } = condition;
        return `${API_HOST_SYSTEM}/journal/template-list?status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_HOST_SYSTEM}/journal/create-template`;
      },
      edit() {
        return `${API_HOST_SYSTEM}/journal/edit-template`;
      },
      detail(id) {
        return `${API_HOST_SYSTEM}/journal/template-detail?template_id=${id}`;
      },
      online() {
        return `${API_HOST_SYSTEM}/journal/template-online`;
      },
      offline() {
        return `${API_HOST_SYSTEM}/journal/template-offline`;
      },
    },
  },

  fake: {
    list(condition) {
      const { page = 1, status = '' } = condition;
      return `${API_HOST_PART}/anti-fake/list?status=${status}&skip=${((page - 1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    edit() {
      return `${API_HOST_PART}/anti-fake/edit`;
    },

    detail(partId) {
      return `${API_HOST_PART}/anti-fake/detail?anti_fake_part_id=${partId}`;
    },

    updateStatus() {
      return `${API_HOST_PART}/anti-fake/update-status`;
    },

    updateCompanyAttendType() {
      return `${API_HOST_PART}/anti-fake/update-company-attend-type`;
    },

    companyAttendList(condition) {
      const { page = 1, id = '' } = condition;
      return `${API_HOST_PART}/anti-fake/company-attend-list?anti_fake_part_id=${id}&skip=${((page -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    companyNotAttendList(condition) {
      const { page = 1, id = '', key = '' } = condition;
      return `${API_HOST_PART}/anti-fake/company-not-attend-list?anti_fake_part_id=${id}&key=${key}&skip=${((page -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    removeCompany() {
      return `${API_HOST_PART}/anti-fake/remove-company-attend`;
    },

    addCompanyAttend() {
      return `${API_HOST_PART}/anti-fake/add-company-attend`;
    },

    qrcodeList(condition) {
      const { page = 1, status = '', companyId = '', partId = '', qrcodeId = '' } = condition;
      return `${API_HOST_PART}/anti-fake/qrcode-list?status=${status}&company_id=${companyId}&anti_fake_part_id=${partId}&anti_fake_part_qrcode_id=${qrcodeId}&skip=${((page -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    insuranceLogList(condition) {
      const { page = 1, status = '', companyId = '', partId = '', qrcodeId = '', autoId = '', customerId = '' } = condition;
      return `${API_HOST_PART}/anti-fake/extension-insurance-log-list?status=${status}&company_id=${companyId}&anti_fake_part_id=${partId}&anti_fake_part_qrcode_id=${qrcodeId}&auto_id=${autoId}&customer_id=${customerId}&skip=${((page -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}`;
    },

    companyCouponList(condition) {
      const { page = 1, companyId = '', limit } = condition;
      return `${API_HOST_PART}/anti-fake/company-coupon-list?company_id=${companyId}&skip=${((page -
        1) || 0) *
      (limit || api.config.limit)}&limit=${limit || api.config.limit}`;
    },
  },

  // 门店
  store: {
    // 水稻会员店
    shuidaoStore: {
      companyList(condition) {
        const { page = 1, cityId = '', key = '', countryId = '', status = '', currentPayLevel = '' } = condition;
        return `${API_HOST_SYSTEM}/company/service-purchase-company-list?city_id=${cityId}&key=${key}&country_id=${countryId}&status=${status}&current_pay_level=${currentPayLevel}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      buyList(condition) {
        const { page = 1, purchaseLevel = '', companyId = '' } = condition;
        return `${API_HOST_SYSTEM}/company/company-service-purchase-list?company_id=${companyId}&purchase_level=${purchaseLevel}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
    },
    // 汽修
    repairShop: {
      list(condition) {
        const { page = 1, key = '', cityId = '', countryId = '', dealerUserId = '', status = '-10', startDate = '', endDate = '', currentPayLevel = '0' } = condition;
        return `${API_HOST_SYSTEM}/company/company-list?key=${key}&city_id=${cityId.trim()}&country_id=${countryId.trim()}&ctime_start=${startDate}&ctime_end=${endDate}&dealer_user_id=${dealerUserId}&status=${status}&current_pay_level=${currentPayLevel}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getAll() {
        return `${API_HOST_SYSTEM}/company/company-list?key=&city_id=&expire_day=&system_type&is_expired=0&create_start_date&create_end_date&seller_phone&skip=0&limit=-1`;
      },
      getSearchAll(condition) {
        const { key = '', cityId = '', countryId = '', dealerUserId = '', status = '-10', currentPayLevel = '0' } = condition;
        return `${API_HOST_SYSTEM}/company/company-list?key=${key}&city_id=${cityId.trim()}&country_id=${countryId.trim()}&dealer_user_id=${dealerUserId}&status=${status}&current_pay_level=${currentPayLevel}&skip=0&limit=-1`;
      },
      serviceLevelList() {
        return `${API_HOST_SYSTEM}/company/service-purchase-level-list`;
      },
      detail(id) {
        return `${API_HOST_SYSTEM}/company/detail?company_id=${id}`;
      },
      statistics(id) {
        return `${API_HOST_SYSTEM}/company/company-statistic?company_id=${id}`;
      },
      orderSummary(id) {
        return `${API_HOST_SYSTEM}/company/order-summary?company_id=${id}`;
      },
      updateDealerUser() {
        return `${API_HOST_SYSTEM}/company/edit-part-dealer-user`;
      },
      visitLogs(condition) {
        const { page = 1, dealerUserId = '', companyId = '', visitTimeStart = '', visitTimeEnd = '' } = condition;
        return `${API_HOST_SYSTEM}/company/visit-log-list?dealer_user_id=${dealerUserId}&company_id=${companyId}&start_date=${visitTimeStart}&end_date=${visitTimeEnd}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      detailLog(id) {
        return `${API_HOST_SYSTEM}/company/visit-log-detail?log_id=${id}`;
      },
      visitPlanList(condition) {
        const { page = 1, dealerUserId = '', companyId = '', startDate = '', endDate = '', status = '-2', fromType = -2 } = condition;
        return `${API_HOST_SYSTEM}/company/visit-plan-list?dealer_user_id=${dealerUserId}&company_id=${companyId}&start_date=${startDate}&end_date=${endDate}&status=${status}&from_type=${fromType}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      createVisitPlan() {
        return `${API_HOST_SYSTEM}/company/create-visit-plan`;
      },
      editVisitPlan() {
        return `${API_HOST_SYSTEM}/company/edit-visit-plan`;
      },
      detailVisitPlan(planId) {
        return `${API_HOST_SYSTEM}/company/visit-plan-detail?plan_id=${planId}`;
      },
      edit() {
        return `${API_HOST_SYSTEM}/company/edit-maintain-service`;
      },
      nearbyGdCompanyList(condition) {
        const { page = 1, key = '', location = '', zoom } = condition;
        return `${API_HOST_SYSTEM}/company/nearby-clue-company-list?key=${key}&location=${location}&zoom=${zoom}&skip=${(page -
          1) * 150}&limit=${150}`;
      },
      nearbyList(condition) {
        const { page = 1, key = '', cityId = '', countryId = '', status = '' } = condition;
        return `${API_HOST_SYSTEM}/company/clue-company-list?key=${key}&city_id=${cityId}&country_id=${countryId}&status=${status}&skip=${(page -
          1) * 700}&limit=${700}`;
      },
      payOrderList(condition) {
        const { page = 1, key = '', cityId = '', countryId = '', status = '' } = condition;
        return `${API_HOST_SYSTEM}/company/pay-order-company-list?key=${key}&city_id=${cityId}&country_id=${countryId}&status=${status}&skip=${(page -
          1) * 700}&limit=${700}`;
      },
      createCallVisit() {
        return `${API_HOST_SYSTEM}/company/create-call-visit-log`;
      },
      brandIntentionList(companyId) {
        return `${API_HOST_SYSTEM}/company/company-brand-intention-list?company_id=${companyId}`;
      },

      serviceLevels(companyId) {
        return `${API_HOST_SYSTEM}/company/service-purchase-level-list-by-company?company_id=${companyId}`;
      },
      giveService() {
        return `${API_HOST_SYSTEM}/company/give-service-purchase`;
      },

      voucher: {
        list(condition) {
          const { page, companyId, fromType, fromId, status } = condition;
          return `${API_HOST_SYSTEM}/company/product-vouchers-list?company_id=${companyId}&from_type=${fromType}&from_id=${fromId}&status=${status}&skip=${(page -
            1) * api.config.limit}&limit=${api.config.limit}`;
        },
        listExport(condition) {
          const { companyId, fromType, fromId, status } = condition;
          return `${API_HOST_SYSTEM}/company/export-product-vouchers?company_id=${companyId}&from_type=${fromType}&from_id=${fromId}&status=${status}&rtick=${new Date().getTime()}`;
        },
        check() {
          return `${API_HOST_SYSTEM}/company/check-product-vouchers`;
        },
      },

      coupon: {
        list(condition) {
          const { page, companyId, fromType, fromId, status } = condition;
          return `${API_HOST_SYSTEM}/company/gift-token-list?company_id=${companyId}&from_type=${fromType}&from_id=${fromId}&status=${status}&skip=${(page -
            1) * api.config.limit}&limit=${api.config.limit}`;
        },
        listExport(condition) {
          const { companyId, fromType, fromId, status } = condition;
          return `${API_HOST_SYSTEM}/company/export-gift-token?company_id=${companyId}&from_type=${fromType}&from_id=${fromId}&status=${status}&rtick=${new Date().getTime()}`;
        },
        check() {
          return `${API_HOST_SYSTEM}/company/check-gift-token`;
        },
      },
    },

    clue: {
      list(condition) {
        const { page = 1, key = '', cityId = '', countryId = '', status = '', bizType = '', visitFrequency = '', coopLevel = ''  } = condition;
        return `${API_HOST_SYSTEM}/company/clue-company-list?key=${key}&city_id=${cityId}&country_id=${countryId}&status=${status}&visit_frequency=${visitFrequency}&coop_level=${coopLevel}&biz_type=${bizType}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      updateStatus() {
        return `${API_HOST_SYSTEM}/company/update-clue-company-status`;
      },
      updateBizType() {
        return `${API_HOST_SYSTEM}/company/update-clue-company-biz-type`;
      },
      updateVisitFrequency() {
        return `${API_HOST_SYSTEM}/company/update-clue-company-visit-frequency`;
      },
      updateCoopLevel() {
        return `${API_HOST_SYSTEM}/company/update-clue-company-coop-level`;
      },
    },
  },

  // 统计
  statistics: {
    dailyReport: {
      list(condition) {
        const { page = 1, startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/part-dealer-report-days?start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      all(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/part-dealer-report-days?start_date=${startDate}&end_date=${endDate}&skip=0&limit=-1`;
      },
      detail(date) {
        return `${API_HOST_SYSTEM}/statistic/part-dealer-user-report?date=${date}`;
      },
    },

    summary: {
      companyDays(condition) {
        const { startDate, endDate, dealerUserId } = condition;
        return `${API_HOST_SYSTEM}/statistic/company-summary-days?start_date=${startDate}&end_date=${endDate}&dealer_user_id=${dealerUserId}`;
      },
      orderDays(condition) {
        const { startDate, endDate, dealerUserId = '' } = condition;
        return `${API_HOST_SYSTEM}/statistic/order-summary-days?start_date=${startDate}&end_date=${endDate}&dealer_user_id=${dealerUserId}`;
      },

      userSellList(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/user-sell-list?start_date=${startDate}&end_date=${endDate}`;
      },

      productSellList(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/product-sell-list?start_date=${startDate}&end_date=${endDate}`;
      },

      orderBrandList(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/order-brand-list?start_date=${startDate}&end_date=${endDate}`;
      },
      orderProductTopList(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/order-product-top-list?start_date=${startDate}&end_date=${endDate}`;
      },
      orderCompanyTopList(condition) {
        const { startDate, endDate } = condition;
        return `${API_HOST_SYSTEM}/statistic/company-sell-list?start_date=${startDate}&end_date=${endDate}`;
      },

      brandCompanyIntention() {
        return `${API_HOST_SYSTEM}/statistic/brand-company-intention-summary`;
      },

      brandCompanyIntentionUser() {
        return `${API_HOST_SYSTEM}/statistic/brand-company-intention-user-summary`;
      },

      companyOrder(condition) {
        const { month, page = 1 } = condition;
        return `${API_HOST_SYSTEM}/statistic/company-order-summary?month=${month}&skip=${(page -
          1) * api.config.halfLimit}&limit=${api.config.halfLimit}`;
      },

      companyVisit(condition) {
        const { month } = condition;
        return `${API_HOST_SYSTEM}/statistic/intention-company-visit-summary?month=${month}`;
      },

      dealCompanyVisit(condition) {
        const { month } = condition;
        return `${API_HOST_SYSTEM}/statistic/deal-company-visit-summary?month=${month}`;
      },

      dealOrder(condition) {
        const { month } = condition;
        return `${API_HOST_SYSTEM}/statistic/deal-order-summary?month=${month}`;
      },
    },

    warehouse: {
      summary() {
        return `${API_HOST_SYSTEM}/statistic/current-summary`;
      },
      brandTopList(sort = 'count') {
        return `${API_HOST_SYSTEM}/statistic/warehouse-product-top-list?sort=${sort}`;
      },
      brandSummary() {
        return `${API_HOST_SYSTEM}/statistic/warehouse-brand-list`;
      },
    },
  },

  setting: {
    company: {
      edit() {
        return `${API_HOST_SYSTEM}/user/part-dealer-edit`;
      },
      detail() {
        return `${API_HOST_SYSTEM}/user/part-dealer-detail`;
      },
    },

    pay: {
      edit() {
        return `${API_HOST_SYSTEM}/user/pay-qrcode-edit`;
      },
    },

    sms: {
      chargeLevels() {
        return `${API_HOST_SYSTEM}/sms/get-charge-level`;
      },
      chargeList(condition) {
        const { page } = condition;
        return `${API_HOST_SYSTEM}/sms/charge-list?skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      groupSendList(condition) {
        const { page, status = '-10' } = condition;
        return `${API_HOST_SYSTEM}/sms/send-plan-list?status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      consumeList(condition) {
        const { page, status = '-10', planId = '', limit = api.config.limit } = condition;
        return `${API_HOST_SYSTEM}/sms/consume-list?status=${status}&plan_id=${planId}&skip=${(page -
          1) * api.config.limit}&limit=${limit}`;
      },

      sendableCompanyCount(userId = '', cityId = '', countryId = '', brandId = '') {
        return `${API_HOST_SYSTEM}/sms/company-count?user_id=${userId}&city_id=${cityId}&country_id=${countryId}&brand_id=${brandId}`;
      },
      sendBatch() {
        return `${API_HOST_SYSTEM}/sms/send-batch-template-sms`;
      },

      template: {
        list(condition) {
          const { page = 1, startDate = '', status = '-10' } = condition;
          return `${API_HOST_SYSTEM}/sms/template-list?create_start_date=${startDate}&status=${status}&skip=${(page -
            1) * api.config.limit}&limit=${api.config.limit}`;
        },
        add() {
          return `${API_HOST_SYSTEM}/sms/create-template`;
        },
        edit() {
          return `${API_HOST_SYSTEM}/sms/edit-template`;
        },
        detail(id) {
          return `${API_HOST_SYSTEM}/sms/template-detail?template_id=${id}`;
        },
      },
    },
  },

  inventory: {
    warehouse: {
      list(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_PART}/warehouse/list?skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      getAll() {
        return `${API_HOST_PART}/warehouse/list?skip=-1&limit=10`;
      },

      create() {
        return `${API_HOST_PART}/warehouse/create`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse/edit`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse/detail?warehouse_id=${id}`;
      },

      stockList(condition) {
        const { page = 1, id = '', productId = '', productItemId = '', key = '', limit } = condition;
        return `${API_HOST_PART}/warehouse/stock-list?keyword=${key}&skip=${(page - 1) *
        (limit || api.config.limit)}&limit=${limit ||
        api.config.limit}&warehouse_id=${id}&product_id=${productId}&product_item_id=${productItemId}`;
      },

      stockLogList(condition) {
        const { page = 1, id = '', productId = '', productItemId = '', ctimeStart = '', ctimeEnd = '', type = '' } = condition;
        return `${API_HOST_PART}/warehouse/stock-log-list?type=${type}&ctime_start=${ctimeStart}&ctime_end=${ctimeEnd}&skip=${(page -
          1) *
        api.config.limit}&limit=${api.config.limit}&warehouse_id=${id}&product_id=${productId}&product_item_id=${productItemId}`;
      },

      vendorList(condition) {
        const { page = 1 } = condition;
        return `${API_HOST_PART}/warehouse/vendor-list?skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      vendorAll() {
        return `${API_HOST_PART}/warehouse/vendor-list?skip=0&limit=-1`;
      },

      createVendor() {
        return `${API_HOST_PART}/warehouse/create-vendor`;
      },

      editVendor() {
        return `${API_HOST_PART}/warehouse/edit-vendor`;
      },

      detailVendor(vendorId) {
        return `${API_HOST_PART}/warehouse/vendor-detail?vendor_id=${vendorId}`;
      },

      export(condition) {
        const { id = '', productId = '', productItemId = '', key = '' } = condition;
        return `${API_HOST_PART}/warehouse/export-stock?skip=0&limit=-1&warehouse_id=${id}&product_id=${productId}&product_item_id=${productItemId}&keyword=${key}&rtick=${new Date().getTime()}`;
      },

      historyStockList(condition) {
        const { page = 1, id = '', productId = '', productItemId = '', key = '', date = '' } = condition;
        return `${API_HOST_PART}/warehouse/stock-backup-list?skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}&warehouse_id=${id}&product_id=${productId}&product_item_id=${productItemId}&keyword=${key}&date=${date}`;
      },

      historyStockExport(condition) {
        const { id = '', productId = '', productItemId = '', key = '', date = '' } = condition;
        return `${API_HOST_PART}/warehouse/export-stock-backup?warehouse_id=${id}&product_id=${productId}&product_item_id=${productItemId}&keyword=${key}&date=${date}&rtick=${new Date().getTime()}`;
      },
    },

    stocktaking: {
      list(condition) {
        const { page = 1, warehouseId = '' } = condition;
        return `${API_HOST_PART}/warehouse/inventory-list?warehouse_id=${warehouseId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },

      create() {
        return `${API_HOST_PART}/warehouse/create-inventory`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse/edit-inventory`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse/inventory-detail?inventory_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/warehouse/delete-inventory`;
      },

      auth() {
        return `${API_HOST_PART}/warehouse/auth-inventory`;
      },
    },

    incomeStatement: {
      list(condition) {
        const { page = 1, warehouseId = '' } = condition;
        return `${API_HOST_PART}/warehouse/income-statement-list?warehouse_id=${warehouseId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },

      createFromInventory() {
        return `${API_HOST_PART}/warehouse/create-income-statement-from-inventory`;
      },

      create() {
        return `${API_HOST_PART}/warehouse/create-income-statement`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse/edit-income-statement`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse/income-statement-detail?income_statement_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/warehouse/delete-income-statement`;
      },

      auth() {
        return `${API_HOST_PART}/warehouse/auth-income-statement`;
      },
    },

    purchase: {
      list(condition) {
        const { page = 1, status = '' } = condition;
        return `${API_HOST_PART}/purchase/list?status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      getAll(status) {
        return `${API_HOST_PART}/purchase/list?status=${status}&skip=0&limit=-1`;
      },

      create() {
        return `${API_HOST_PART}/purchase/create`;
      },

      edit() {
        return `${API_HOST_PART}/purchase/edit`;
      },

      detail(id) {
        return `${API_HOST_PART}/purchase/detail?purchase_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/purchase/delete`;
      },

      submit() {
        return `${API_HOST_PART}/purchase/deal`;
      },

      itemList(condition) {
        const { page = 1, purchaseId = '' } = condition;
        return `${API_HOST_PART}/purchase/item-list?purchase_id=${purchaseId}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      getAllItemList(condition) {
        const { purchaseId = '' } = condition;
        return `${API_HOST_PART}/purchase/item-list?purchase_id=${purchaseId}&skip=0&limit=-1`;
      },

      addItem() {
        return `${API_HOST_PART}/purchase/add-item`;
      },

      editItem() {
        return `${API_HOST_PART}/purchase/edit-item`;
      },

      deleteItem() {
        return `${API_HOST_PART}/purchase/delete-item`;
      },
    },

    warehouseMove: {
      list(condition) {
        const { page = 1, status = '' } = condition;
        return `${API_HOST_PART}/warehouse-move/list?status=${status}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      getAll(status) {
        return `${API_HOST_PART}/purchase/list?status=${status}&skip=0&limit=-1`;
      },

      create() {
        return `${API_HOST_PART}/warehouse-move/create`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse-move/edit`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse-move/detail?move_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/warehouse-move/delete`;
      },

      submit() {
        return `${API_HOST_PART}/warehouse-move/deal`;
      },

      itemList(condition) {
        const { page = 1, moveId = '' } = condition;
        return `${API_HOST_PART}/warehouse-move/item-list?move_id=${moveId}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      itemAll(moveId) {
        return `${API_HOST_PART}/warehouse-move/item-list?move_id=${moveId}&skip=0&limit=-1`;
      },

      addItem() {
        return `${API_HOST_PART}/warehouse-move/add-item`;
      },

      editItem() {
        return `${API_HOST_PART}/warehouse-move/edit-item`;
      },
    },

    warehouseInput: {
      list(condition) {
        const { page = 1, status = '', fromId = '', type = '', warehouseId = '' } = condition;
        return `${API_HOST_PART}/warehouse-input/list?from_id=${fromId}&type=${type}&warehouse_id=${warehouseId}&status=${status}&skip=${(page -
          1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      create() {
        return `${API_HOST_PART}/warehouse-input/create-from-purchase`;
      },

      moveCreate() {
        return `${API_HOST_PART}/warehouse-input/create-from-move`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse-input/edit`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse-input/detail?input_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/warehouse-input/delete`;
      },

      submit() {
        return `${API_HOST_PART}/warehouse-input/deal`;
      },

      itemList(condition) {
        const { inputId = '' } = condition;
        return `${API_HOST_PART}/warehouse-input/item-list?input_id=${inputId}&skip=0&limit=-1`;
      },

      addItem() {
        return `${API_HOST_PART}/warehouse-input/add-item`;
      },

      editItem() {
        return `${API_HOST_PART}/warehouse-input/edit-item`;
      },

      deleteItem() {
        return `${API_HOST_PART}/warehouse-input/delete-item`;
      },

      detailItem(inputItemId) {
        return `${API_HOST_PART}/warehouse-input/item-detail?input_item_id=${inputItemId}`;
      },
    },

    warehouseOutput: {
      list(condition) {
        const { page = 1, status = '', type = '', warehouseId = '' } = condition;
        return `${API_HOST_PART}/warehouse-output/list?type=${type}&warehouse_id=${warehouseId}&status=${status}&skip=${(page -
          1) *
        api.config.limit}&limit=${api.config.limit}`;
      },

      create() {
        return `${API_HOST_PART}/warehouse-output/create-from-sell-order`;
      },

      moveCreate() {
        return `${API_HOST_PART}/warehouse-output/create-from-move`;
      },

      edit() {
        return `${API_HOST_PART}/warehouse-output/edit`;
      },

      detail(id) {
        return `${API_HOST_PART}/warehouse-output/detail?output_id=${id}`;
      },

      remove() {
        return `${API_HOST_PART}/warehouse-output/delete`;
      },

      submit() {
        return `${API_HOST_PART}/warehouse-output/deal`;
      },

      itemList(condition) {
        const { outputId = '' } = condition;
        return `${API_HOST_PART}/warehouse-output/item-list?output_id=${outputId}&skip=0&limit=-1`;
      },

      addItem() {
        return `${API_HOST_PART}/warehouse-output/add-item`;
      },

      editItem() {
        return `${API_HOST_PART}/warehouse-output/edit-item`;
      },

      deleteItem() {
        return `${API_HOST_PART}/warehouse-output/delete-item`;
      },

      detailItem(outputItemId) {
        return `${API_HOST_PART}/warehouse-output/item-detail?output_item_id=${outputItemId}`;
      },
    },
  },

  auto: {
    list(condition) {
      const { startDate = '', endDate = '', key = '', page = 1 } = condition;
      return `${API_HOST_PART}/auto/list?create_start_date=${startDate}&create_end_date=${endDate}&key=${key}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    searchAll(key) {
      return `${API_HOST_PART}/auto/list?key=${key}&create_start_date=&create_end_date=&skip=0&limit=-1`;
    },
    create() {
      return `${API_HOST_PART}/auto/create`;
    },
    edit() {
      return `${API_HOST_PART}/auto/edit`;
    },
    setAdminAndUser() {
      return `${API_HOST_PART}/auto/set-admin-and-user`;
    },
    detail(id) {
      return `${API_HOST_PART}/auto/detail?auto_id=${id}`;
    },

    // 维保记录
    listByAutoId(condition) {
      const { autoId, startDate = '', endDate = '', page = 1 } = condition;
      return `${API_HOST_PART}/auto/maintain-intention-list-by-auto-id?auto_id=${autoId}&start_date=${startDate}&end_date=${endDate}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    // 保险记录
    insuranceList(condition) {
      const { autoId, page = 1 } = condition;
      return `${API_HOST_PART}/auto/insurance-log-list?auto_id=${autoId}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    createInsurance() {
      return `${API_HOST_PART}/auto/create-insurance-log`;
    },

    editInsurance() {
      return `${API_HOST_PART}/auto/edit-insurance-log`;
    },

    config() {
      return `${API_HOST_PART}/auto/get-insurance-config`;
    },

    companyList() {
      return `${API_HOST_PART}/auto/get-insurance-company-list`;
    },

    // 年检记录
    yearlyList(condition) {
      const { autoId, page = 1 } = condition;
      return `${API_HOST_PART}/auto/annual-inspection-log-list?auto_id=${autoId}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    createYearly() {
      return `${API_HOST_PART}/auto/create-annual-inspection-log`;
    },

    editYearly() {
      return `${API_HOST_PART}/auto/edit-annual-inspection-log`;
    },

    detailYearly(autoId, inspectionLogId) {
      return `${API_HOST_PART}/auto/annual-inspection-log-detail?inspection_log_id=${inspectionLogId}&auto_id=${autoId}`;
    },

    // 用车记录
    useAutoByAutoId(condition) {
      const { autoId, page = 1 } = condition;
      return `${API_HOST_PART}/auto/use-auto-list-by-auto-id?auto_id=${autoId}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    useAutoByUserId(userId) {
      return `${API_HOST_PART}/auto/use-auto-list-by-user-id?user_id=${userId}&skip=0&limit=-1`;
    },

    startUseAuto() {
      return `${API_HOST_PART}/auto/user-start-use-auto`;
    },

    stopUseAuto() {
      return `${API_HOST_PART}/auto/user-stop-use-auto`;
    },

    useAutoDetail(autoId, useId) {
      return `${API_HOST_PART}/auto/user-use-auto-detail?auto_id=${autoId}&use_auto_id=${useId}`;
    },

    // 运行轨迹
    trackList(condition) {
      const { autoId, page = 1, date = '' } = condition;
      return `${API_HOST_PART}/auto/track-list?auto_id=${autoId}&date=${date}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    bandGps() {
      return `${API_HOST_PART}/auto/band-gps`;
    },

    unBandGps() {
      return `${API_HOST_PART}/auto/unband-gps`;
    },
  },

  conference: {
    list(condition) {
      const { status = '', page } = condition;
      return `${API_HOST_PART}/conference/list?status=${status}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    create() {
      return `${API_HOST_PART}/conference/create`;
    },

    edit() {
      return `${API_HOST_PART}/conference/edit`;
    },

    detail(conferenceId) {
      return `${API_HOST_PART}/conference/detail?conference_id=${conferenceId}`;
    },

    startLive() {
      return `${API_HOST_PART}/conference/start-live`;
    },

    startNotice() {
      return `${API_HOST_PART}/conference/start-notice`;
    },

    attendList(condition) {
      const { conferenceId, page, isInRoom, companyId = '' } = condition;
      return `${API_HOST_PART}/conference/attend-list?conference_id=${conferenceId}&company_id=${companyId}&is_in_room=${isInRoom}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    attendExport(condition) {
      const { conferenceId, page, isInRoom } = condition;
      return `${API_HOST_PART}/conference/export-attend?conference_id=${conferenceId}&is_in_room=${isInRoom}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}&rtick=${new Date().getTime()}`;
    },

    getQRCode(id) {
      return `${API_HOST_PART}/conference/get-qrcode-pic-data?conference_id=${id}`;
    },
    updateOnlineCount() {
      return `${API_HOST_PART}/conference/update-live-online-count`;
    },

    stopAttendEnterRoom() {
      return `${API_HOST_PART}/conference/stop-attend-enter-room`;
    },
  },

  enroll: {
    list(condition) {
      const { status = '', page, limit } = condition;
      return `${API_HOST_PART}/event/list?status=${status}&skip=${((page -
        1) || 0) * (limit || api.config.limit)}&limit=${(limit || api.config.limit)}`;
    },

    create() {
      return `${API_HOST_PART}/event/create`;
    },

    edit() {
      return `${API_HOST_PART}/event/edit`;
    },

    detail(eventId) {
      return `${API_HOST_PART}/event/detail?event_id=${eventId}`;
    },

    attendList(condition) {
      const { eventId, page, limit } = condition;
      return `${API_HOST_PART}/event/attend-list?event_id=${eventId}&skip=${((page -
        1) || 0) * (limit || api.config.limit)}&limit=${(limit || api.config.limit)}`;
    },

    getQRCode(id) {
      return `${API_HOST_PART}/event/get-qrcode-pic-data?event_id=${id}`;
    },

    export(eventId) {
      return `${API_HOST_PART}/event/export-attend-list?event_id=${eventId}&rtick=${new Date().getTime()}`;
    },

    clueList(condition) {
      const { eventId, page = 1 } = condition;
      return `${API_HOST_PART}/event/clue-list?event_id=${eventId}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    exportClue(id) {
      return `${API_HOST_PART}/event/export-clue-list?event_id=${id}&rtick=${new Date().getTime()}`;
    },
  },
};

export default api;
