import 'babel-polyfill';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { HomeOutlined, UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/zh-cn';
import _ from 'lodash';

import apiDaotian from '../../configs/api-daotian';

import server from '../../utils/server';
import AuthUtil from '../../utils/AuthUtil';
import DownloadGoogle from '../../components/DownloadGoogle';

import Help from './Help';

import routes from './routes';
import PrivateRouteWithAuth from '../PrivateRouteWithAuth';
import Breadcrumb from '../Breadcrumb';

import companyMenu from '../../configs/menu-daotian';

import { getUserPermissions, setUserPermissions } from '../../reducers/auth/authActions';

moment.locale('zh-cn');

require('babel-polyfill');

require('../../styles/common.less');
require('../../styles/app.less');
require('../../styles/layout.css');

require('../../styles/reset.css');

const packageFile = require('../../../package.json');

const logo = require('../../images/nav/daotian_logo.png');
const logoText = require('../../images/logo/logo_daotian@2x.png');

const { Header, Footer, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavbar: sessionStorage.getItem('activeNavbar') || 'daotian',
      activeMenu: 'daotian_store',
      collapsed: localStorage.getItem('collapsed') === 'true' || false,
      openKeys: ['home'],

      permissionMap: new Map(),
      companySubMenu: [],
    };

    [
      'handleLogout',
      'handleToggle',
      'handleMenuClick',
      'handleOpenChange',
    ].map(method => this[method] = this[method].bind(this));
  }

  componentDidMount() {
    if (AuthUtil.isLogin()) {
      this.getMenuPermissions();
    }

    const path = location.pathname;
    const parentMenuKey = this.getMenuItemParentKey(path);

    this.setState({
      activeMenu: path,
      openKeys: parentMenuKey ? [parentMenuKey] : [],
    });
    sessionStorage.setItem('menu', path);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s19.cnzz.com/z_stat.php?id=1262229427&web_id=1262229427';
    document.body.appendChild(script);

    AuthUtil.checkLoginUserInfo(apiDaotian.system.userInfo());
  }

  handleLogout() {
    sessionStorage.clear();
    Object.keys(localStorage).forEach((key) => {
      if (key !== 'is_show_print_tip' && key !== 'is_show_enterprise_tip') {
        localStorage.removeItem(key);
      }
    });
    location.href = '/login';
  }

  handleToggle() {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.state.collapsed) {
      localStorage.setItem('collapsed', false);
    } else {
      localStorage.setItem('collapsed', true);
    }
  }

  handleMenuClick(e) {
    const event = e;
    this.setState({ activeMenu: event.key });
    sessionStorage.setItem('menu', event.key);
  }

  handleOpenChange(openKeys) {
    const state = this.state;
    const latestOpenKey = openKeys.find(key => !(state.openKeys.indexOf(key) > -1));
    const latestCloseKey = state.openKeys.find(key => !(openKeys.indexOf(key) > -1));

    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    this.setState({ openKeys: nextOpenKeys });
  }

  getAncestorKeys(key) {
    const map = {
      '/aftersales/work-order/new': ['aftersales'],
      '/aftersales/part-sale/new': ['aftersales'],
      '/aftersales/consumptive-material/index': ['aftersales'],
      '/aftersales/work-order/index': ['aftersales'],
      '/aftersales/part-sale/index': ['aftersales'],
      '/aftersales/auto/index': ['aftersales'],
      '/aftersales/customer/score': ['aftersales'],
      '/warehouse/purchase/new': ['warehouse'],
      '/warehouse/purchase-reject/new': ['warehouse'],
      '/warehouse/stocktaking/new': ['warehouse'],
      '/warehouse/purchase/index': ['warehouse'],
      '/warehouse/purchase-reject/index': ['warehouse'],
      '/warehouse/stocktaking/index': ['warehouse'],
      '/warehouse/part/index': ['warehouse'],
      '/aftersales/inventory-warn/index': ['warehouse'],
      '/warehouse/supplier/index': ['warehouse'],
      '/warehouse/logs/index': ['warehouse'],
      '/warehouse/markup-rate/index': ['warehouse'],
      '/warehouse/part/store': ['warehouse'],
      '/finance/expense/list': ['finance'],
      '/finance/presales-income/list': ['finance'],
      '/finance/monthly_report': ['finance'],
      '/finance/fixed-assets/index': ['finance'],
      '/marketing/times/list': ['marketing'],
      '/marketing/discount/list': ['marketing'],
      '/marketing/coupon/sale': ['marketing'],
      '/marketing/coupon-card/list': ['marketing'],
      '/marketing/coupon-card-order': ['marketing'],
      '/marketing/bargain/index': ['marketing'],
      '/marketing/bargain/prize': ['marketing'],
      '/company/sms-manage': ['marketing'],
      '/marketing/statistics/coupon': ['marketing'],
    };
    return map[key] || [];
  }

  /**
   * 获取当前item的最顶级item key
   * 使用try catch包裹是为了找到parentKey后跳出forEach循环
   * */
  getMenuItemParentKey(currentPath) {
    const companySubMenu = require('../../configs/menu-daotian').default;
    let parentKey;

    if (companySubMenu && companySubMenu.length > 0) {
      try {
        companySubMenu.forEach((menuItem) => {
          if (menuItem.subMenu.length > 0) {
            menuItem.subMenu.forEach((subMenuItem) => {
              if (subMenuItem.path === currentPath) {
                parentKey = menuItem.key;
                throw new Error('找到menuItem的Key啦');
              } else if (subMenuItem.path === 'group' && subMenuItem.items.length > 0) {
                subMenuItem.items.forEach((groupMenuItem) => {
                  if (groupMenuItem.path === currentPath) {
                    parentKey = menuItem.key;
                    throw new Error('Whoops!');
                  }
                });
              }
            });
          }
        });
      } catch (e) {
      }
    }
    return parentKey;
  }

  getArrayPermission(list) {
    const listArray = [];
    list.forEach((item) => {
      if (item.children) {
        item.children.forEach((value) => {
          listArray.push(value);
        });
        delete item.children;
      }
      listArray.push(item);
    });
    return listArray;
  }

  // 获取登录用户的所有权限
  getMenuPermissions() {
    const { permissionMap } = this.state;

    // 店总：公司的所有权限
    if (AuthUtil.isStoreAdmin()) {
      server.get(apiDaotian.user.getCompanyPermissions(), (data) => {
        const permissions = this.getArrayPermission(data.res.list);
        this.props.actions.setUserPermissions(permissions);

        permissions.forEach(permission => permissionMap.set(permission.path, permission));
        this.setState({ permissionMap }, () => this.getSubMenu());
      });
    } else {
      // 普通员工权限
      server.get(apiDaotian.user.getCommonUserPermissions(), (data) => {
        const permissions = data.res.list;
        this.props.actions.setUserPermissions(permissions);

        permissions.forEach(permission => permissionMap.set(permission.item_path, permission));
        this.setState({ permissionMap }, () => this.getSubMenu());
      });
    }
  }

  // 该函数是从配置文件companySubmenuConfig找到符合权限的菜单放到一个新的数组companySubMenu中
  getSubMenu() {
    const { companySubMenu } = this.state;

    companyMenu.forEach((menu) => {
      const menuItem = _.cloneDeep(menu);

      if (this.checkSubmenuPermission(menuItem)) {
        companySubMenu.push(menuItem);

        const subMenuIndex = companySubMenu.length - 1; // 当前操作的数组下标
        companySubMenu[subMenuIndex].subMenu = [];

        menu.subMenu.forEach((subMenu) => {
          const subMenuDeepCopy = _.cloneDeep(subMenu);

          // isPrivate: true，只有门店拥有者(即后台配置的门店店总)才有权限访问
          if (subMenuDeepCopy.path === 'group') {
            if (subMenuDeepCopy.isPrivate && AuthUtil.getLoginUserInfo().uid !==
              AuthUtil.getLoginUserStoreInfo().adminUserId) {
              return false;
            }

            if (this.checkGroupPermission(subMenuDeepCopy)) {
              companySubMenu[subMenuIndex].subMenu.push(subMenuDeepCopy);

              // 构造companySubMenu数组结构
              const itemIndex = companySubMenu[subMenuIndex].subMenu.length - 1; // 新的groupItems下标
              companySubMenu[subMenuIndex].subMenu[itemIndex].items = [];

              // 将符合权限的group中的内容拷贝到subMenu中
              subMenu.items.map((group) => {
                if (this.checkPermission(group.path)) {
                  companySubMenu[subMenuIndex].subMenu[itemIndex].items.push(group);
                }
              });
            }
          } else if (this.checkPermission(subMenu.path)) {
            companySubMenu[subMenuIndex].subMenu.push(subMenu);
          }
        });
      }
    });

    this.setState({ companySubMenu });
  }

  checkPermission(path) {
    return this.state.permissionMap.has(path.slice(1, path.length));
  }

  checkSubmenuPermission(menu) {
    for (const subMenuItem of menu.subMenu) {
      // 检测该菜单栏是否是group 若是，则判断是否有group中第一项的权限，如果有则认为有该group所有权限
      if (subMenuItem.path === 'group') {
        for (const groupItem of subMenuItem.items) {
          if (this.checkPermission(groupItem.path)) {
            return true;
          }
        }
      } else {
        // 如果该菜单栏不是group，则检测是否有该菜单栏权限
        if (this.checkPermission(subMenuItem.path)) {
          return true;
        }
      }
    }
    return false;
  }

  checkGroupPermission(group) {
    for (const groupItem of group.items) {
      if (this.checkPermission(groupItem.path)) {
        return true;
      }
    }
    return false;
  }

  renderSubMenuIcon(collapsed, sub) {
    const MenuIcon = sub.icon;
    return (
      <span>
        {!collapsed && <MenuIcon />}
        <span>{sub.name}</span>
      </span>
    );
  }

  render() {
    const { activeMenu, openKeys, companySubMenu, collapsed } = this.state;
    const { uid, name, department, companyName, companyId } = AuthUtil.getLoginUserInfo();

    const showMenu = classNames({
      'layout-menu': true,
      hide: !uid,
    });

    const showSetting = classNames({
      'layout-setting': !!uid,
      'bg-white': AuthUtil.isSuperAdministrator() || AuthUtil.isChainAdministrator() ||
        AuthUtil.isRegionAdministrator(),
      hide: !uid,
    });

    const settingContainer = classNames({
      'setting-container': true,
      'no-company': department >= 0,
    });

    const logoContainer = classNames({
      'logo-daotian': true,
      'logo-collapsed': collapsed,
    });

    const menuItemStyle = { height: '28px', lineHeight: '28px' };

    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={160}
          style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}
        >
          <div className={logoContainer}>
            {!!collapsed && (
              <img src={logo} style={{ width: 17, height: 17 }} alt="水稻汽车" />
            )}

            {!collapsed && (
              <img className="ml-10" src={logoText} style={{ height: 17 }} alt="水稻汽车" />
            )}
          </div>

          <div className={showMenu}>
            <Menu
              theme="dark"
              mode="inline"
              onClick={this.handleMenuClick}
              // inlineCollapsed={collapsed}
              selectedKeys={[activeMenu]}
              openKeys={openKeys}
              onOpenChange={this.handleOpenChange}
            >
              <MenuItem key="/home">
                <Link to={{ pathname: '/home' }}>
                  {!collapsed && <HomeOutlined />}
                  <span>首页</span>
                </Link>
              </MenuItem>

              {companySubMenu.length > 0 && (
                companySubMenu.map(sub => (
                  <SubMenu
                    key={sub.key}
                    title={this.renderSubMenuIcon(collapsed, sub)}
                  >
                    {
                      sub.subMenu.map((menuList) => {
                        if (menuList.path === 'group') {
                          return (
                            <MenuItemGroup key={menuList.name} title={menuList.name}>
                              {menuList.items.map(groupItem => (
                                <Menu.Item key={groupItem.path} style={menuItemStyle}>
                                  <Link
                                    to={{ pathname: groupItem.path }}
                                    target={groupItem.target || ''}
                                  >
                                    <span>{groupItem.name}</span>
                                  </Link>
                                </Menu.Item>
                              ))}
                            </MenuItemGroup>
                          );
                        }

                        return (
                          <MenuItem key={menuList.path} style={menuItemStyle}>
                            <Link to={{ pathname: menuList.path }} target={menuList.target || ''}>
                              <span>{menuList.name}</span>
                            </Link>
                          </MenuItem>
                        );
                      })
                    }
                  </SubMenu>
                ))
              )}
            </Menu>
          </div>
        </Sider>

        {/* 内容区 */}
        <Layout style={{ marginLeft: collapsed ? 80 : 160 }}>
          <Header
            className="header"
            style={{
              background: '#fff',
              padding: 0,
              borderBottom: '1px solid #e1e1e1',
              height: 50,
            }}
          >
            {this.state.collapsed ? (
              <MenuUnfoldOutlined
                className="menu-trigger"
                onClick={this.handleToggle}
              />
            ) : (
              <MenuFoldOutlined
                className="menu-trigger"
                onClick={this.handleToggle}
              />
            )}

            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index.toString()}
                  path={route.path}
                  exact={route.exact}
                  component={() => <Breadcrumb breadcrumbName={route.breadcrumbName} />}
                />
              ))}
            </Switch>

            <div className={showSetting}>
              <div className={settingContainer}>
                <div className="company-name">
                  {Number(companyId) === 1
                    ? null
                    : (
                      <div className="company-name-content">
                        {/* <span className="company-type">{cooperationTypeShort}</span> */}
                        {companyName}
                      </div>
                    )
                  }
                </div>

                <div className="user-setting">
                  <p className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
                    <UserOutlined style={{ marginLeft: 10, marginRight: 5 }} />
                    {name}
                    <a className="btn-logout" onClick={this.handleLogout}>退出</a>
                  </p>
                </div>
              </div>
            </div>
          </Header>

          <Content
            style={{
              margin: 20,
              padding: '20px',
              background: '#fff',
              minHeight: (document.body.clientHeight || 744) - 156,
              border: '1px solid #e1e1e1',
            }}
          >

            <Switch>
              {routes.map((route, index) => (
                <PrivateRouteWithAuth
                  key={index.toString()}
                  path={route.path}
                  exact={route.exact}
                  component={route.render}
                />
              ))}
            </Switch>
          </Content>

          <Footer style={{ textAlign: 'center' }}>
            Copyright © {new Date().getFullYear()} 水稻汽车 版权所有 v{packageFile.version}
            <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
              京ICP备15040810号
            </a>
          </Footer>
        </Layout>

        <Help />

        <DownloadGoogle />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { userPermissions } = state.auth;
  return { userPermissions };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getUserPermissions, setUserPermissions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
