const API_BASE = `${window.baseURL}/daotian_web`;

/**
 * 稻田系统API配置
 */
const api = {
  config: {
    limit: 15, // 查询全部数据时，limit=-1即可
    halfLimit: 10,
    skip: 0,
  },

  system: {
    getVerifyCode() {
      return `${API_BASE}/user/get-code`;
    },
    register() {
      return `${API_BASE}/user/register`;
    },
    login() {
      return `${API_BASE}/user/login`;
    },
    genLoginIId() {
      return `${API_BASE}/user/gen-login-id`;
    },
    loginById(loginId) {
      return `${API_BASE}/user/login-by-id?login_id=${loginId}`;
    },
    logout() {
      return `${API_BASE}/user/logout`;
    },
    userInfo() {
      return `${API_BASE}/user/info`;
    },
    refressLogin() {
      return `${API_BASE}/user/refress-login`;
    },
  },

  company: {
    detail() {
      return `${API_BASE}/company/detail`;
    },
    edit() {
      return `${API_BASE}/company/edit`;
    },
    verifyQCDAAccount() {
      return `${API_BASE}/company/check-qcda-password`;
    },
    loginLimit() {
      return `${API_BASE}/company/login-limit`;
    },
    editPrinter() {
      return `${API_BASE}/company/edit-printer`;
    },

    keyList(key) {
      return `${API_BASE}/company/maintain-company-list?skip=0&limit=20&key=${key}`;
    },
    getAllStores(key) {
      return `${API_BASE}/company/company-list?key=${key}&skip=0&limit=-1`;
    },
    companyList(key) {
      return `${API_BASE}/company/maintain-company-list?key=${key}&skip=0&limit=5`;
    },

    // todo 人事薪资管理功能暂时关闭
    getCommissionRate() {
      return `${API_BASE}/company/commission-rate-detail`;
    },
    editCommissionRate() {
      return `${API_BASE}/company/edit-commission-rate`;
    },
  },

  auto: {
    list(condition = {}) {
      const limit = condition.limit || api.config.limit;
      return `${API_BASE}/auto/auto-list?key=${condition.key}&auto_brand_id=${condition.autoBrandId}&skip=${(limit ===
      -1 ? 0 : (condition.page - 1) || api.config.skip) * limit}&limit=${limit}`;
    },
    listByCustomerId(customerId) {
      return `${API_BASE}/auto/auto-list-by-customer?customer_id=${customerId}`;
    },
    search(condition) {
      return `${API_BASE}/auto/search-autos?key=${condition.key}`;
    },
    genNewId() {
      return `${API_BASE}/auto/gen-new-auto-id`;
    },

    // 添加编辑车辆，客户信息选填
    add() {
      return `${API_BASE}/auto/create-auto`;
    },
    edit() {
      return `${API_BASE}/auto/edit-auto`;
    },

    // 为客户添加或编辑车辆
    addToCustomer() {
      return `${API_BASE}/auto/create-customer-auto`;
    },
    editToCustomer() {
      return `${API_BASE}/auto/edit-customer-auto`;
    },

    // customerId非必填
    detail(autoId, customerId = '') {
      return `${API_BASE}/auto/auto-detail?auto_id=${autoId}&customer_id=${customerId}`;
    },

    remove() {
      return `${API_BASE}/auto/delete-customer-auto`;
    },
  },

  customer: {
    list(condition) {
      const { page, key } = condition;
      return `${API_BASE}/customer/phone-customer-list?key=${key}&skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}`;
    },
    search(key) {
      return `${API_BASE}/customer/search-customers?key=${key}`;
    },
    genNewId() {
      return `${API_BASE}/customer/gen-new-id`;
    },
    add() {
      return `${API_BASE}/customer/create`;
    },
    edit() {
      return `${API_BASE}/customer/edit`;
    },
    detail(customerId) {
      return `${API_BASE}/customer/info?customer_id=${customerId}`;
    },

    remove() {
      return `${API_BASE}/customer/delete-customer`;
    },

    getSourceTypes(sourceDeal = '') {
      return `${API_BASE}/customer/source-types?source_deal=${sourceDeal}`;
    },
    getCustomerUnpayAmount(customerId) {
      return `${API_BASE}/customer/total-unpay-amount?customer_id=${customerId}`;
    },
    getCustomerPayLog() {
      return `${API_BASE}/maintain/intention-pay-log`;
    },

    customerList(condition) {
      const { page, key } = condition;
      return `${API_BASE}/customer/customer-list?key=${key}&skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}`;
    },
  },

  // 1. 销售
  presales: {
    // 1.意向客户
    intention: {
      list(condition) {
        const {
          page,
          key,
          source,
          intention_level,
          create_day,
          intention_brand,
          budget_level,
          is_mortgage,
        } = condition;

        return `${API_BASE}/purchase/potential-list?key=${key}&source=${source}&intention_level=${intention_level}&create_day=${create_day}&intention_brand=${intention_brand}&budget_level=${budget_level}&is_mortgage=${is_mortgage}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      listByCustomerId(customerId) {
        return `${API_BASE}/purchase/intention-list-by-customer-id?customer_id=${customerId}`;
      },

      add() {
        return `${API_BASE}/purchase/create-intention`;
      },
      edit() {
        return `${API_BASE}/purchase/edit-intention`;
      },
      lost() {
        return `${API_BASE}/purchase/intention-fail`;
      },
      detail(customerId, intentionId) {
        return `${API_BASE}/purchase/intention-detail?customer_id=${customerId}&_id=${intentionId}`;
      },
      detailByAutoId(customerId, autoId) {
        return `${API_BASE}/purchase/intention-detail-by-auto-id?customer_id=${customerId}&auto_id=${autoId}`;
      },

      getFailTypes() {
        return `${API_BASE}/purchase/intention-fail-types`;
      },
      getBudgetLevels() {
        return `${API_BASE}/purchase/intention-budget-levels`;
      },
    },

    // 2. 成交单
    deal: {
      list(condition) {
        const { key, page, payStatus, lastDealDays } = condition;
        return `${API_BASE}/purchase/auto-deal-list?key=${key}&pay_status=${payStatus}&last_deal_days=${lastDealDays}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      listByCustomerId(customerId) {
        return `${API_BASE}/purchase/auto-deal-list-by-customer-id?customer_id=${customerId}`;
      },
      add() {
        return `${API_BASE}/purchase/create-auto-deal`;
      },
      edit() {
        return `${API_BASE}/purchase/edit-auto-deal`;
      },
      band() {
        return `${API_BASE}/purchase/auto-deal-band-auto`;
      },
      detail(customerId, autoId) {
        return `${API_BASE}/purchase/auto-deal-detail?customer_id=${customerId}&auto_id=${autoId}`;
      },
      detailByAutoId(customerId, autoId) {
        return `${API_BASE}/purchase/auto-deal-detail-by-auto-id?customer_id=${customerId}&auto_id=${autoId}`;
      },

      // 装潢
      decoration: {
        add() {
          return `${API_BASE}/purchase/create-decoration-log`;
        },
        edit() {
          return `${API_BASE}/purchase/edit-decoration-log`;
        },
        detail(customerId, autoDealId) {
          return `${API_BASE}/purchase/decoration-log-detail?customer_id=${customerId}&auto_deal_id=${autoDealId}`;
        },
      },
    },

    // 金融
    finance: {
      // todo confirm
      auth(id) {
        return `${API_BASE}/financial/auth-purchase-income?auto_deal_id=${id}`;
      },

      // 按揭
      loan: {
        // unused now
        list(customerId) {
          return `${API_BASE}/financial/loan-log-list?customer_id=${customerId}`;
        },
        add() {
          return `${API_BASE}/financial/create-loan-log`;
        },
        edit() {
          return `${API_BASE}/financial/edit-loan-log`;
        },
        detail(customerId, autoDealId) {
          return `${API_BASE}/financial/loan-log-detail?customer_id=${customerId}&auto_deal_id=${autoDealId}`;
        },

        guaranteeCompanies() {
          return `${API_BASE}/financial/get-guarantee-company-list`;
        },
      },

      // 保险
      insurance: {
        // unused now
        list(customerId) {
          return `${API_BASE}/financial/insurance-log-list?customer_id=${customerId}`;
        },
        add() {
          return `${API_BASE}/financial/create-insurance-log`;
        },
        edit() {
          return `${API_BASE}/financial/edit-insurance-log`;
        },
        detail(customerId, autoDealId) {
          return `${API_BASE}/financial/insurance-log-detail?customer_id=${customerId}&auto_deal_id=${autoDealId}`;
        },

        companies() {
          return `${API_BASE}/financial/get-insurance-company-list`;
        },
      },
    },

    getInsuranceDetail(customerId, autoId) {
      return `${API_BASE}/auto/get-insurance-info?customer_id=${customerId}&auto_id=${autoId}`;
    },
    getAutoPurchaseDetail(autoDealId) {
      return `${API_BASE}/financial/purchase-detail?auto_deal_id=${autoDealId}`;
    },
    editPurchaseIncome(autoDealId) {
      return `${API_BASE}/financial/edit-purchase-income?auto_deal_id=${autoDealId}`;
    },
    checkPurchaseIncome(autoDealId) {
      return `${API_BASE}/financial/check-purchase-income?auto_deal_id=${autoDealId}`;
    },

    autoDealInfo(customer_id, autoDealId) {
      return `${API_BASE}/purchase/auto-deal-detail?customer_id=${customer_id}&auto_deal_id=${autoDealId}`;
    },
  },

  // 2. 售后
  aftersales: {
    maintain: {
      getUploadToken(customerId, intentionId, fileType) {
        return `${API_BASE}/maintain/get-operation-record-upload-token?customer_id=${customerId}&intention_id=${intentionId}&file_type=${fileType}`;
      },
      getFileUrl(customerId, intentionId, fileType) {
        return `${API_BASE}/maintain/get-operation-record-file-url?customer_id=${customerId}&intention_id=${intentionId}&file_type=${fileType}`;
      },
    },

    getConsumableList(condition) {
      const { key = '', page = 1, startTime, endTime, status } = condition;
      return `${API_BASE}/part/consumable-list?key=${key}&start_date=${startTime}&end_date=${endTime}&skip=${((page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}&status=${status}`;
    },
    createConsumable() {
      return `${API_BASE}/part/create-consumable`;
    },
    authorizeConsumable() {
      return `${API_BASE}/part/authorize-consumable`;
    },
    getConsumableDetail(_id) {
      return `${API_BASE}/part/consumable-detail?consumable_id=${_id}`;
    },
    deleteConsumable() {
      return `${API_BASE}/part/delete-consumable`;
    },
    editConsumable() {
      return `${API_BASE}/part/edit-consumable`;
    },

    maintainIntentionPayFail() {
      return `${API_BASE}/maintain/intention-pay-fail`;
    },

    workOrder: {
      list(condition) {
        const { page = 1, key = '', status = '-2', payStatus = '-2', type = '' } = condition;
        return `${API_BASE}/maintain/intention-list?key=${key}&status=${status}&pay_status=${payStatus}&type=${type}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      listByCustomerAndAutoId(autoId = '', customerId = '') {
        return `${API_BASE}/maintain/intention-list-by-auto-id?auto_id=${autoId}&customer_id=${customerId}&skip=0&limit=-1`;
      },
      add() {
        return `${API_BASE}/maintain/create-intention`;
      },
      edit() {
        return `${API_BASE}/maintain/edit-intention`;
      },
      destroy() {
        return `${API_BASE}/maintain/intention-cancel`;
      },
      finish() {
        return `${API_BASE}/maintain/intention-finish`;
      },
      rollback() {
        return `${API_BASE}/maintain/intention-rollback-pay`;
      },
      detail(id) {
        return `${API_BASE}/maintain/intention-detail?_id=${id}`;
      },

      getItems(id) {
        return `${API_BASE}/maintain/intention-item-list?intention_id=${id}`;
      },

      getParts(id) {
        return `${API_BASE}/maintain/intention-part-list?intention_id=${id}`;
      },

      payByApp() {
        return `${API_BASE}/maintain/intention-pay-by-app`;
      },
      payOnAccount() {
        return `${API_BASE}/maintain/intention-pay-on-account`;
      },
      payOnRepayment() {
        return `${API_BASE}/maintain/intention-pay-back`;
      },
      payByHand() {
        return `${API_BASE}/maintain/intention-pay-by-hand`;
      },

      sendSms() {
        return `${API_BASE}/maintain/intention-send-sms`;
      },
    },

    fakeWorkOrder: {
      list(condition) {
        const { page = 1, key = '', status = '-2', payStatus = '-2' } = condition;
        return `${API_BASE}/maintain/fake-intention-list?key=${key}&status=${status}&pay_status=${payStatus}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_BASE}/maintain/create-fake-intention`;
      },
      edit() {
        return `${API_BASE}/maintain/edit-fake-intention`;
      },
      detail(id) {
        return `${API_BASE}/maintain/fake-intention-detail?_id=${id}`;
      },
      destroy() {
        return `${API_BASE}/maintain/fake-intention-cancel`;
      },
    },

    member: {
      list(condition) {
        const { page, key } = condition;
        return `${API_BASE}/customer/prepay-customer-list?key=${key}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      charge() {
        return `${API_BASE}/customer/prepay-charge`;
      },
      chargeLog(customerId) {
        return `${API_BASE}/customer/prepay-charge-list?customer_id=${customerId}`;
      },
      consumeLog(customerId) {
        return `${API_BASE}/customer/prepay-consume-list?customer_id=${customerId}`;
      },
    },

    maintainItem: {
      list(condition) {
        const { page, key, maintainType = '' } = condition;
        return `${API_BASE}/maintain/item-list?keyword=${key}&maintain_type=${maintainType}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${page ? api.config.limit : ''}`;
      },
      searchList(condition) {
        const { key, maintainType = '' } = condition;
        return `${API_BASE}/maintain/item-list?keyword=${key}&maintain_type=${maintainType}&skip=0&limit=-1`;
      },
      types() {
        return `${API_BASE}/maintain/type-list`;
      },
      add() {
        return `${API_BASE}/maintain/add-item`;
      },
      edit() {
        return `${API_BASE}/maintain/edit-item`;
      },
    },

    // 配件销售
    createPartSell() {
      return `${API_BASE}/part-sell/create`;
    },
    getPartSellDetail(order_id) {
      return `${API_BASE}/part-sell/detail?order_id=${order_id}`;
    },
    getPartSellPartList(order_id) {
      return `${API_BASE}/part-sell/part-list?order_id=${order_id}`;
    },
    partSellPayAll() {
      return `${API_BASE}/part-sell/pay-all`;
    },
    partSellPayOnAccount() {
      return `${API_BASE}/part-sell/pay-on-account`;
    },
    partSellPayBack() {
      return `${API_BASE}/part-sell/pay-back`;
    },
    partSellEdit() {
      return `${API_BASE}/part-sell/edit`;
    },
    partSellPayLog(orderId, customerId) {
      return `${API_BASE}/part-sell/pay-log?order_id=${orderId}&customer_id=${customerId}`;
    },
    rollbackPay() {
      return `${API_BASE}/part-sell/rollback-pay`;
    },

    partDestroy() {
      return `${API_BASE}/part-sell/cancel`;
    },

    getPartSellList(condition) {
      const { key, status, page } = condition;
      return `${API_BASE}/part-sell/list?skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}&key=${key}&status=${status}`;
    },

    weekly: {
      saveIntentionSummary() {
        return `${API_BASE}/statistic/save-intention-summary`;
      },
      getIntentionSummary(startTime) {
        return `${API_BASE}/statistic/get-intention-summary?start_date=${startTime}`;
      },

      weekLowGrossProfitIntentionList(startTime) {
        return `${API_BASE}/statistic/week-low-gross-profit-intention-list?start_date=${startTime}`;
      },
      saveLowGrossProfitIntention() {
        return `${API_BASE}/statistic/save-low-gross-profit-intention`;
      },
      lowGrossProfitIntentionDetail(startTime) {
        return `${API_BASE}/statistic/low-gross-profit-intention-detail?start_date=${startTime}`;
      },

      saveInvalidItemPartIntention() {
        return `${API_BASE}/statistic/save-invalid-item-part-intention`;
      },

      invalidItemPartIntentionDetail(startTime) {
        return `${API_BASE}/statistic/invalid-item-part-intention-detail?start_date=${startTime}`;
      },

      weekOverPurchasePartList(startTime) {
        return `${API_BASE}/statistic/week-over-purchase-part-list?start_date=${startTime}`;
      },
      saveOverPurchasePart() {
        return `${API_BASE}/statistic/save-over-purchase-part`;
      },
      overPurchasePartDetail(startTime) {
        return `${API_BASE}/statistic/over-purchase-part-detail?start_date=${startTime}`;
      },

      weekPriceRisePurchasePartList(startTime) {
        return `${API_BASE}/statistic/week-price-rise-purchase-part-list?start_date=${startTime}`;
      },

      savePriceRisePurchasePart() {
        return `${API_BASE}/statistic/save-price-rise-purchase-part`;
      },

      priceRisePurchasePartDetail(startTime) {
        return `${API_BASE}/statistic/price-rise-purchase-part-detail?start_date=${startTime}`;
      },

      weekRejectPartList(startTime) {
        return `${API_BASE}/statistic/week-reject-part-list?start_date=${startTime}`;
      },

      saveRejectPart() {
        return `${API_BASE}/statistic/save-reject-part`;
      },

      rejectPartDetail(startTime) {
        return `${API_BASE}/statistic/reject-part-detail?start_date=${startTime}`;
      },
    },

    comment: {
      getCommentList(condition) {
        return `${API_BASE}/comment/list?skip=${((condition.page - 1) || 0) *
        api.config.limit}&limit=${api.config.limit}&start_date=${condition.startDate}&end_date=${condition.endDate}&rate=${condition.rate}&source=${condition.source}`;
      },
    },
  },

  // 3. 仓库
  warehouse: {
    part: {
      list(condition) {
        const { isOnlyStock = '', key = '', page = 1, partType = '', brand = '', scope = '' } = condition;
        return `${API_BASE}/part/part-list?keyword=${key}&is_only_stock=${isOnlyStock}&skip=${((page -
          1) ||
          0) * api.config.limit}&limit=${api.config.limit}&part_type=${partType ||
        ''}&brand=${brand || ''}&scope=${scope ||
        ''}&status=${condition.status || ''}`;
      },
      searchList(condition) {
        return `${API_BASE}/part/part-list?keyword=${condition.key}&is_only_stock=${condition.isOnlyStock || ''}&skip=0&limit=20&part_type=${condition.partType ||
        ''}&brand=${condition.brand || ''}&scope=${condition.scope ||
        ''}&status=${condition.status || ''}`;
      },
      updatePartStatus() {
        return `${API_BASE}/part/update-part-status`;
      },
      partMaintainUseLogList(condition) {
        return `${API_BASE}/part/maintain-use-log-list?start_date=${condition.startDate}&end_date=${condition.endDate}&skip=${((condition.page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}&part_id=${condition.partId}`;
      },
      detail(partId) {
        return `${API_BASE}/part/part-detail?part_id=${partId}`;
      },
      searchBySupplierId(key, supplierId) {
        supplierId = supplierId ? supplierId : 0;
        return `${API_BASE}/part/supplier-part-search?keyword=${key}&supplier_id=${supplierId}`;
      },
      add() {
        return `${API_BASE}/part/add-part`;
      },
      edit() {
        return `${API_BASE}/part/edit-part`;
      },
      partLowAmountList(page) {
        return `${API_BASE}/part/low-amount-part-list?skip=${((page - 1) || 0) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      partTypeMarkup() {
        return `${API_BASE}/part/edit-part-type-markup`;
      },
      editPartType() {
        return `${API_BASE}/part/edit-part-type`;
      },
    },

    category: {
      superPartTypeList() {
        return `${API_BASE}/part/super-part-type-list`;
      },
      partAllPartTypes() {
        return `${API_BASE}/part/all-part-types`;
      },
      partTypeList(pid, page) {
        return `${API_BASE}/part/part-type-list?pid=${pid}&skip=${((page - 1) || 0) *
        api.config.limit * 2}&limit=${api.config.limit * 2}`;
      },
      partTypeFullList(pid, page) {
        return `${API_BASE}/part/part-type-full-list?pid=${pid}&skip=${((page - 1) || 0) *
        api.config.limit * 2}&limit=${api.config.limit * 2}`;
      },

      add() {
        return `${API_BASE}/part/add-part-type`;
      },
      edit() {
        return `${API_BASE}/part/edit-part-type`;
      },
      search(keyword) {
        return `${API_BASE}/part/search-part-type?keyword=${keyword}`;
      },
    },

    supplier: {
      list(condition) {
        return `${API_BASE}/part/supplier-list?company=${condition.company}&skip=${((condition.page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_BASE}/part/add-supplier`;
      },
      edit() {
        return `${API_BASE}/part/edit-supplier`;
      },
      search(companyName) {
        return `${API_BASE}/part/supplier-list?company=${companyName}&skip=0&limit=-1`;
      },
      getAll() {
        return `${API_BASE}/part/supplier-list?company=&skip=0&limit=-1`;
      },
      pay() {
        return `${API_BASE}/part/pay-supplier`;
      },
    },

    purchase: {
      list(condition) {
        const { page, startDate, endDate, supplierId, type, status, payStatus } = condition;
        return `${API_BASE}/part/purchase-list?start_date=${startDate}&end_date=${endDate}&supplier_id=${supplierId}&type=${type}&status=${status}&pay_status=${payStatus}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      getListBySupplierAndPayStatus(supplierId, payStatus, page) {
        return `${API_BASE}/part/purchase-list?start_date=&end_date=&supplier_id=${supplierId}&type=-1&status=1&pay_status=${payStatus}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getAllBySupplierAndPayStatus(supplierId, payStatus) {
        return `${API_BASE}/part/purchase-list?start_date=&end_date=&supplier_id=${supplierId}&type=-1&status=1&pay_status=${payStatus}&skip=0&limit=-1`;
      },
      add() {
        return `${API_BASE}/part/create-purchase`;
      },
      edit() {
        return `${API_BASE}/part/edit-purchase`;
      },
      cancel() {
        return `${API_BASE}/part/cancel-purchase`;
      },
      import() {
        return `${API_BASE}/part/import-purchase`;
      },
      pay() {
        return `${API_BASE}/part/pay-purchase`;
      },
      detail(id) {
        return `${API_BASE}/part/purchase-detail?purchase_id=${id}`;
      },
      items(id, page) {
        return `${API_BASE}/part/purchase-item-list?purchase_id=${id}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      itemsAll(id) {
        return `${API_BASE}/part/purchase-item-list?purchase_id=${id}&skip=0&limit=-1`;
      },
      itemsBySupplierAndPart(partId, supplierId, page) {
        return `${API_BASE}/part/purchase-item-list-by-supplier-part?part_id=${partId}&supplier_id=${supplierId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
    },

    reject: {
      list(condition) {
        const { page, startDate, endDate, supplierId, status, payStatus } = condition;
        return `${API_BASE}/part/reject-list?start_date=${startDate}&end_date=${endDate}&supplier_id=${supplierId}&status=${status}&pay_status=${payStatus}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      getListBySupplierAndPayStatus(supplierId, payStatus, page) {
        return `${API_BASE}/part/reject-list?start_date=&end_date=&supplier_id=${supplierId}&status=1&pay_status=${payStatus}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getAllBySupplierAndPayStatus(supplierId, payStatus) {
        return `${API_BASE}/part/reject-list?start_date=&end_date=&supplier_id=${supplierId}&status=1&pay_status=${payStatus}&skip=0&limit=-1`;
      },
      add() {
        return `${API_BASE}/part/create-reject`;
      },
      edit() {
        return `${API_BASE}/part/edit-reject`;
      },
      cancel() {
        return `${API_BASE}/part/cancel-reject`;
      },
      export() {
        return `${API_BASE}/part/export-reject`;
      },
      pay() {
        return `${API_BASE}/part/pay-reject`;
      },
      detail(id) {
        return `${API_BASE}/part/reject-detail?reject_id=${id}`;
      },
      items(id, page) {
        return `${API_BASE}/part/reject-item-list?reject_id=${id}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
    },

    stocktaking: {
      list(condition) {
        const { page, startDate, endDate } = condition;
        return `${API_BASE}/part/stocktaking-list?start_date=${startDate}&end_date=${endDate}&skip=${((page -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      parts(id, page) {
        return `${API_BASE}/part/stocktaking-item-list?stocktaking_id=${id}&skip=${((page - 1) ||
          0) * api.config.limit}&limit=${page ? api.config.limit : ''}`;
      },
      getAllParts(id) {
        return `${API_BASE}/part/stocktaking-item-list?stocktaking_id=${id}&skip=0&limit=1000000`;
      },
      new() {
        return `${API_BASE}/part/create-stocktaking`;
      },
      edit() {
        return `${API_BASE}/part/update-stocktaking-items`;
      },
      cancel() {
        return `${API_BASE}/part/cancel-stocktaking`;
      },
      detail(id) {
        return `${API_BASE}/part/stocktaking-detail?stocktaking_id=${id}`;
      },
      checkUpdateAllStockaking(id) {
        return `${API_BASE}/part/check-update-all-stocktaking?stocktaking_id=${id}`;
      },
      auth() {
        return `${API_BASE}/part/authorize-stocktaking`;
      },
      import() {
        return `${API_BASE}/part/import-stocktaking`;
      },
      updateParts() {
        return `${API_BASE}/part/update-stocktaking-items`;
      },

      getStocktakingQRCode(id) {
        return `${API_BASE}/part/stocktaking-authorize-qrcode-pic-data?stocktaking_id=${id}`;
      },


      stockLogs(condition) {
        return API_BASE + (`/part/stock-log-list?
        part_id=${condition.partId || ''}
        &type=${condition.type}
        &from_type=${condition.fromType || ''}
        &skip=${(condition.page - 1) * api.config.limit}
        &limit=${api.config.limit}
        &start_date=${condition.startDate}
        &end_date=${condition.endDate}
        &keyword=${condition.keyword || ''}
        &brand=${condition.brand || ''}
        &scope=${condition.scope || ''}
        &part_type=${condition.partType || ''}`).replace(/\s/g, '');
      },
    },
  },

  // 5. 报表
  statistics: {
    // 售前
    getPurchaseSummary(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-summary?start_date=${startTime}&end_date=${endTime}`;
    },

    getNewPotentialAndIntentionDaysData(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-potential-summary-days?start_date=${startTime}&end_date=${endTime}`;
    },
    getNewDealDaysData(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-auto-deals-days?start_date=${startTime}&end_date=${endTime}`;
    },
    getPurchaseFailDays(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-fail-days?start_date=${startTime}&end_date=${endTime}`;
    },

    getPurchaseIncomeInfo(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-incomes?start_date=${startTime}&end_date=${endTime}`;
    },

    getIntentionLostInfo(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-fail-types?start_date=${startTime}&end_date=${endTime}`;
    },

    getIntentionInfo(startTime, endTime) {
      return `${API_BASE}/statistic/purchase-intentions?start_date=${startTime}&end_date=${endTime}`;
    },

    // 售后
    getMaintainTypeCount(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-type-count?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainDailyFinance(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-finance-days?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainTypeIncomes(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-type-summary?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainPayTypes(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-income-pay-types?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainIncomeUnpay(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-income-unpay?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainCouponByLevel(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-coupon-cards?start_date=${startTime}&end_date=${endTime}`;
    },

    getMaintainSummary(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-summary?start_date=${startTime}&end_date=${endTime}`;
    },
    getMaintainCountDaysData(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-count-days?start_date=${startTime}&end_date=${endTime}`;
    },
    getMaintainIncomeDaysData(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-incomes-days?start_date=${startTime}&end_date=${endTime}`;
    },
    getMaintainAutoPartDays(startTime, endTime) {
      return `${API_BASE}/statistic/maintain-auto-part-days?start_date=${startTime}&end_date=${endTime}`;
    },
    getMaintainTypeDays(condition) {
      return `${API_BASE}/statistic/maintain-type-days?start_date=${condition.startTime}&end_date=${condition.endTime}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    // 今日数据统计
    getMaintainSummaryToday() {
      return `${API_BASE}/statistic/maintain-today-summary`;
    },
  },

  // 6、财务
  finance: {
    getIncomeList(condition) {
      return `${API_BASE}/financial/income-list?start_time=${condition.start_time}&end_time=${condition.end_time}&account_type=${condition.account_type}&status=${condition.status}&transfer_status=${condition.transfer_status}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    getFinancialSummary(month) {
      return `${API_BASE}/financial/financial-summary?month=${month}`;
    },

    getPresalesIncomeList(condition) {
      return `${API_BASE}/financial/purchase-income-list?start_date=${condition.start_date}&end_date=${condition.end_date}&from_type=${condition.pay_type}&plate_num=${condition.key}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    newIncomeStatement() {
      return `${API_BASE}/financial/create-check-sheet`;
    },

    getIncomeStatementDetail(id) {
      return `${API_BASE}/financial/check-sheet-detail?_id=${id}`;
    },

    scanQRCodeToVerify(id, type) {
      return `${API_BASE}/financial/confirm-check-sheet-user?_id=${id}&user_type=${type}`;
    },

    confirmIncomeStatement() {
      return `${API_BASE}/financial/confirm-check-sheet`;
    },

    getIncomeTransferList(condition) {
      return `${API_BASE}/financial/income-transfer-list?end_date=${condition.end_date}&company_id=${condition.company_id}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    genIncomeUntransfer(company_id, end_date) {
      return `${API_BASE}/financial/gen-income-untransfer?end_date=${end_date}&company_id=${company_id}`;
    },

    setIncomeTransfered() {
      return `${API_BASE}/financial/set-income-transfered`;
    },

    incomeListByTransferId(transfer_id) {
      return `${API_BASE}/financial/income-list-by-transfer-id?transfer_id=${transfer_id}`;
    },

    getExpenseList(page, startTime, endTime, type, sub_type) {
      return `${API_BASE}/financial/journal-list?skip=${((page - 1) || 0) *
      api.config.limit}&limit=${api.config.limit}&start_date=${startTime}&end_date=${endTime}&type=${type}&sub_type=${sub_type}`;
    },

    dailyList(page, startTime, endTime, type, sub_type) {
      return `${API_BASE}/financial/journal-daily-list?skip=${((page - 1) || 0) *
      api.config.limit}&limit=${api.config.limit}&start_date=${startTime}&end_date=${endTime}&type=${type}&sub_type=${sub_type}`;
    },

    newDailyExpense() {
      return `${API_BASE}/financial/create-daily`;
    },

    newExpenseType() {
      return `${API_BASE}/financial/create-daily-sub-type`;
    },

    getProjectTypeList(type) {
      return `${API_BASE}/financial/journal-sub-type-list?type=${type}&key&skip=0&limit=-1`;
    },

    getDailyTypeList(type, key = '') {
      return `${API_BASE}/financial/daily-sub-type-list?type=${type}&key=${key}&skip=0&limit=-1`;
    },

    fixedAssets: {
      list(condition) {
        const { key, page } = condition;
        return `${API_BASE}/fixed-assets/fixed-assets-list?key=${key}&skip=${((parseInt(page, 10) -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      listById(id) {
        return `${API_BASE}/fixed-assets/fixed-assets-detail?fixed_assets_id=${id}`;
      },
      add() {
        return `${API_BASE}/fixed-assets/add-assets`;
      },
      editStatus() {
        return `${API_BASE}/fixed-assets/change-status`;
      },
      useLogs(fixedAssetsId) {
        return `${API_BASE}/fixed-assets/fixed-assets-log-list?fixed_assets_id=${fixedAssetsId}`;
      },
    },

    receiveMoneyQRCode: {
      incomeList(condition) {
        const { page, startTime, endTime } = condition;
        return `${API_BASE}/wallet/income-list?start_date=${startTime}&end_date=${endTime}&skip=${((parseInt(page, 10) -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      withdrawList(condition) {
        const { page, startTime, endTime } = condition;
        return `${API_BASE}/wallet/withdraw-list?start_date=${startTime}&end_date=${endTime}&skip=${((parseInt(page, 10) -
          1) || 0) * api.config.limit}&limit=${api.config.limit}`;
      },
      withdraw() {
        return `${API_BASE}/wallet/withdraw`;
      },
    },
  },

  // 7. 人事
  user: {
    genNewId() {
      return `${API_BASE}/user/gen-user-new-id`;
    },
    getUploadToken(userId, fileType) {
      return `${API_BASE}/user/get-user-upload-token?user_id=${userId}&file_type=${fileType}`;
    },
    getFileUrl(userId, fileType) {
      return `${API_BASE}/user/get-user-file-url?user_id=${userId}&file_type=${fileType}`;
    },
    info() {
      return `${API_BASE}/user/info`;
    },
    add() {
      return `${API_BASE}/user/create`;
    },
    edit() {
      return `${API_BASE}/user/edit`;
    },
    fire() {
      return `${API_BASE}/user/fire`;
    },
    getList(condition) {
      return `${API_BASE}/user/user-full-list?company_id=&department=${condition.department}&salary_group=${condition.salaryGroup}&keyword=${condition.key}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getDetail(userId) {
      return `${API_BASE}/user/user-full-detail?_id=${userId}`;
    },

    // certificate
    getCaList(userId) {
      return `${API_BASE}/user/user-ca-list?user_id=${userId}`;
    },
    deleteUserCertificate(userId, userCaId) {
      return `${API_BASE}/user/user-ca-delete?user_id=${userId}&_id=${userCaId}`;
    },

    getUsersByDeptAndRole(departmentId = '', role = '') {
      return `${API_BASE}/user/user-list?department=${departmentId}&role=${role}`;
    },

    // salary
    getSalaryGroups() {
      return `${API_BASE}/user/salary-group-list`;
    },
    getSalaryItems(userId) {
      return `${API_BASE}/user/user-salary-items?user_id=${userId}`;
    },
    updateSalaryInfo() {
      return `${API_BASE}/user/edit-salary-v2`;
    },
    prepareCalculateSalary(userId, month) {
      return `${API_BASE}/user/prepare-calculate-salary?user_id=${userId}&month=${month}`;
    },
    calculateSalary(userId, month) {
      return `${API_BASE}/user/calculate-salary?user_id=${userId}&month=${month}`;
    },
    getSalaryHistory(userId) {
      return `${API_BASE}/user/self-salary-list?user_id=${userId}`;
    },
    calculateTax() {
      return `${API_BASE}/user/calculate-tax`;
    },
    freezeSalary() {
      return `${API_BASE}/user/freeze-salary`;
    },
    getSalaryList(condition) {
      return `${API_BASE}/user/salary-list?department=${condition.department}&month=${condition.month}&keyword=${condition.key}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    getUserMeritPayItem(condition) {
      return `${API_BASE}/user/user-merit-pay-items?user_id=${condition.userId}&month=${condition.month}&skip=${((condition.page -
        1) || 0) * api.config.limit}&limit=${api.config.limit}&type=${condition.type}`;
    },

    getDepartmentRoles(departmentId) {
      return `${API_BASE}/user/department-roles?department=${departmentId}`;
    },

    getAllDepartmentRoles() {
      return `${API_BASE}/user/all-department-roles`;
    },

    getUsers() {
      return `${API_BASE}/user/user-list`;
    },

    getPurchaseUsers(isLeader) {
      return `${API_BASE}/user/purchase-user-list?is_leader=${isLeader}`;
    },
    getMaintainUsers(isLeader) {
      return `${API_BASE}/user/maintain-user-list?is_leader=${isLeader}`;
    },

    // permissions
    // 1.1 公司的全部权限(菜单项)
    getCompanyPermissions() {
      return `${API_BASE}/user/company-auth-item-list`;
    },
    // 1.2 普通用户权限(菜单项)
    getCommonUserPermissions() {
      return `${API_BASE}/user/self-auth-item-list`;
    },
    checkPermission(path) {
      return `${API_BASE}/user/check-auth?path=${path}`;
    },

    // 2.1 员工的权限(人事管理，新增编辑时使用)
    getUserPermissions(userId) {
      return `${API_BASE}/user/user-auth-item-list?user_id=${userId}`;
    },
    // 2.2 员工所属角色的全部权限
    getRolePermissions(roleId) {
      return `${API_BASE}/user/company-role-item-list?role=${roleId}`;
    },

    editPermission() {
      return `${API_BASE}/user/edit-auth`;
    },

    getWeChatQRCode(id) {
      return `${API_BASE}/user/wechat-band-qrcode?_id=${id}`;
    },
  },

  // 8. 营销 deprecated
  coupon: {
    // need new api
    list(condition = {}) {
      const { page, key, status, isFilterExpire } = condition;
      return `${API_BASE}/coupon/coupon-item-list?keyword=${key}&status=${status}&is_filter_expire=${isFilterExpire ||
      0}&skip=${((parseInt(page, 10) - 1) || 0) * api.config.limit}&limit=${page
        ? api.config.limit
        : '-1'}`;
    },
    add() {
      return `${API_BASE}/coupon/create-coupon-item`;
    },
    updateStatus() {
      return `${API_BASE}/coupon/update-coupon-item-status`;
    },

    getCouponList(condition = {}) {
      return `${API_BASE}/coupon/coupon-item-list?skip=${((parseInt(condition.page, 10) - 1) ||
        0) *
      api.config.limit}&limit=${condition.page ? api.config.limit : ''}&keyword=${condition.key ||
      ''}&status=${condition.status || ''}&type=${condition.type ||
      ''}&is_filter_expire=${condition.isFilterExpire || 0}`;
    },
    getCouponItemDetail(id) {
      return `${API_BASE}/coupon/coupon-item-detail?id=${id}`;
    },
    getCouponCardTypeList(keyword, status, pageSetting = {}) {
      if (!pageSetting.page || pageSetting.page < 1) {
        pageSetting.page = 1;
      }
      if (!pageSetting.pageSize || pageSetting.pageSize < 0) {
        pageSetting.pageSize = api.config.limit;
      }
      const skip = (pageSetting.page - 1) * pageSetting.pageSize;
      const limit = pageSetting.pageSize;
      return `${API_BASE}/coupon/coupon-card-type-list?skip=${skip}&limit=${limit}&keyword=${keyword}&status=${status}`;
    },

    getCouponCustomerCount(companyId, couponCardType, autoBrandIds) {
      return `${API_BASE}/coupon/customer-count?company_id=${companyId}&coupon_card_type=${couponCardType}&auto_brand_ids=${autoBrandIds}`;
    },

    // 活动
    getCouponActivityList(condition = {}) {
      const { page, key } = condition;
      return `${API_BASE}/coupon/coupon-activity-list?keyword=${key || ''}&limit=${page
        ? api.config.limit : ''}&skip=${((page - 1) || 0) * api.config.limit}`;
    },

    createCouponActivity() {
      return `${API_BASE}/coupon/create-coupon-activity`;
    },

    editCouponActivity() {
      return `${API_BASE}/coupon/edit-coupon-activity`;
    },

    getCouponActivityDetail(id) {
      return `${API_BASE}/coupon/coupon-activity-detail?id=${id}`;
    },
    couponGiveCouponItem() {
      return `${API_BASE}/coupon/give-coupon-item`;
    },
    getCouponActivityPushList(condition) {
      return `${API_BASE}/coupon/coupon-activity-push-list?activity_id=${condition.id}&skip=${((condition.page -
        1) || 0) * 5}&limit=5`;
    },

    //  助力砍价
    bargainActivityList(condition, all = false) {
      return `${API_BASE}/coupon/bargain-activity-list?skip=${((Number(condition.page) - 1) ||
        0) * api.config.limit}&limit=${all ? '-1' : api.config.limit}`;
    },
    bargainActivityAttendList(condition) {
      return `${API_BASE}/coupon/bargain-activity-attend-list?activity_id=${condition.activityId}&keyword=${condition.key}&skip=${((Number(condition.page) -
        1) || 0) *
      api.config.limit}&limit=${api.config.limit}&status=${condition.status}&user_id=${condition.inviterId}`;
    },
    bargainActivityChange() {
      return `${API_BASE}/coupon/bargain-activity-change`;
    },

    bonusEditBargainActivity() {
      return `${API_BASE}/coupon/bonus-edit-bargain-activity`;
    },

    // 砍价记录列表
    bargainActivityAssistList(condition) {
      return `${API_BASE}/coupon/bargain-activity-assist-list?activity_id=${condition.activityId}&attend_id=${condition.attendId}&skip=${((Number(condition.page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    createBargainActivity() {
      return `${API_BASE}/coupon/create-bargain-activity`;
    },
    editBargainActivity() {
      return `${API_BASE}/coupon/edit-bargain-activity`;
    },
    bargainActivityDetail(activityId) {
      return `${API_BASE}/coupon/bargain-activity-detail?activity_id=${activityId}`;
    },

    //  领券活动
    baseEditCouponActivity() {
      return `${API_BASE}/coupon/base-edit-coupon-activity`;
    },
    bonusEditCouponActivity() {
      return `${API_BASE}/coupon/bonus-edit-coupon-activity`;
    },
    couponActivityList(condition) {
      return `${API_BASE}/coupon/coupon-activity-list?limit=${api.config.limit}&skip=${((Number(condition.page) -
        1) || 0) * api.config.limit}&keyword=${condition.key ||
      ''}&is_show_all=${condition.isShowAll || '1'}`;
    },
    couponActivityListAll() {
      return `${API_BASE}/coupon/coupon-activity-list?limit=&skip=&keyword=&is_show_all=0`;
    },
    couponActivityDetail(condition) {
      return `${API_BASE}/coupon/coupon-activity-detail?id=${condition.id}`;
    },
    pushCouponActivity() {
      return `${API_BASE}/coupon/push-coupon-activity`;
    },
    couponActivityPushList(condition) {
      return `${API_BASE}/coupon/coupon-activity-push-list?activity_id=${condition.id}&skip=${((Number(condition.page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    couponActivityAttendList(condition) {
      return `${API_BASE}/coupon/coupon-activity-attend-list?activity_id=${condition.activityId}&keyword=${condition.key}&user_id=${condition.inviterId}&skip=${((Number(condition.page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    couponActivityGrabList(condition) {
      return `${API_BASE}/coupon/coupon-activity-grab-list?activity_id=${condition.activityId}&attend_id=${condition.attendId}&skip=${((Number(condition.page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
  },

  // 9. 任务/提醒
  task: {
    getRenewalInsuranceList(between, status, page) {
      return `${API_BASE}/task/insurance-list?between=${between}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getMaintenanceList(between, status, page) {
      return `${API_BASE}/task/maintain-list?between=${between}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getYearlyInspectionList(between, status, page) {
      return `${API_BASE}/task/inspection-list?between=${between}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getDebtList(startDate, endDate, status, page) {
      return `${API_BASE}/task/debt-list?start_date=${startDate}&end_date=${endDate}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getMemberCardExpireList(between, status, page) {
      return `${API_BASE}/task/coupon-card-list?between=${between}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getBirthdayList(between, status, page) {
      return `${API_BASE}/task/birthday-list?between=${between}&status=${status}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },
    getCommonTask(status, startDate, endDate, page) {
      return `${API_BASE}/task/common-list?status=${status}&start_date=${startDate}&end_date=${endDate}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${api.config.limit}`;
    },

    /**
     * 客户详情页提醒列表
     */
    // 1.保养
    getCustomerMaintainReminders(customerId) {
      return `${API_BASE}/task/maintain-list?customer_id=${customerId}&between=0-30&status=-2&skip=0&limit=-1`;
    },

    // 2.续保
    getCustomerRenewalReminders(customerId) {
      return `${API_BASE}/task/insurance-list?customer_id=${customerId}&between=0-45&status=-1&skip=0&limit=-1`;
    },

    // 3.年检
    getCustomerYearlyInspectionReminders(customerId) {
      return `${API_BASE}/task/inspection-list?customer_id=${customerId}&between=0-30&status=-1&skip=0&limit=-1`;
    },

    // 4.套餐卡
    getCustomerCouponCardReminders(customerId) {
      return `${API_BASE}/task/coupon-card-list?customer_id=${customerId}&between=0-30&status=-1&skip=0&limit=-1`;
    },

    // 5.生日
    getCustomerBirthdayReminders(customerId) {
      return `${API_BASE}/task/birthday-list?customer_id=${customerId}&between=0-30&status=-1&skip=0&limit=-1`;
    },

    // 6.催收款
    getCustomerDebtReminders(customerId, startDate, endDate) {
      return `${API_BASE}/task/debt-list?customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}&status=-1&skip=0&limit=-1`;
    },

    // 7.其他回访
    getCustomerCommonRemimders(customerId, startDate, endDate) {
      return `${API_BASE}/task/common-list?customer_id=${customerId}&status=-1&start_date=${startDate}&end_date=${endDate}&skip=0&limit=-1`;
    },

    // 1.创建
    createCustomerTask() {
      return `${API_BASE}/task/create-common-task`;
    },

    taskFollowUp() {
      return `${API_BASE}/task/follow-up`;
    },
    taskFollowHistory(taskId, taskType) {
      return `${API_BASE}/task/follow-up-list?task_id=${taskId}&task_type=${taskType}`;
    },
    tastSummary() {
      return `${API_BASE}/task/task-summary`;
    },
  },

  // new repair api

  // 营销
  marketing: {
    coupon: {
      list(condition) {
        const { page, key, status, isFilterExpired } = condition;
        return `${API_BASE}/coupon-package/coupon-list?keyword=${key}&status=${status}&is_filter_expire=${isFilterExpired}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_BASE}/coupon-package/create-coupon`;
      },
      edit() {
        return `${API_BASE}/coupon-package/edit-coupon`;
      },
      updateStatus() {
        return `${API_BASE}/coupon-package/update-coupon-status`;
      },
      detail(id) {
        return `${API_BASE}/coupon-package/coupon-detail?coupon_id=${id}`;
      },
      // 发放
      delivery() {
        return `${API_BASE}/coupon-package/give-coupon`;
      },
      // 发放记录
      deliveredLogs(id, page) {
        return `${API_BASE}/coupon-package/coupon-push-list?coupon_id=${id}&skip=${(page - 1) *
        api.config.limit}&limit=${api.config.limit}`;
      },
      // 已发放某张优惠券的客户列表
      deliveredCustomers(id, pushId, page) {
        return `${API_BASE}/coupon-package/coupon-push-customer-list?coupon_id=${id}&coupon_push_id=${pushId}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      // 某一客户的所有优惠券
      deliveredLogsOfCustomer(customerId) {
        return `${API_BASE}/coupon-package/customer-coupon-list?customer_id=${customerId}`;
      },
      // 某一客户的所有优惠项目
      deliveredItemsOfCustomer(customerId, isFilterRemain = 0) {
        return `${API_BASE}/coupon-package/customer-coupon-item-list?customer_id=${customerId}&is_filter_remain=${isFilterRemain}`;
      },
      // 某一客户某张优惠券中已核销的项目的工单列表
      deliveredAndUsedItemsOfCustomer(customerId, couponItemId) {
        return `${API_BASE}/coupon-package/customer-coupon-intention-list?customer_id=${customerId}&customer_coupon_package_item_id=${couponItemId}`;
      },
    },

    // 套餐卡
    couponCard: {
      list(condition) {
        const { page, key, status } = condition;
        return `${API_BASE}/coupon-package/card-list?keyword=${key}&status=${status}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      getAllUsingCards() {
        return `${API_BASE}/coupon-package/card-list?keyword=&status=1&skip=0&limit=-1`;
      },
      add() {
        return `${API_BASE}/coupon-package/create-card`;
      },
      edit() {
        return `${API_BASE}/coupon-package/edit-card`;
      },
      updateStatus() {
        return `${API_BASE}/coupon-package/update-card-status`;
      },
      detail(id) {
        return `${API_BASE}/coupon-package/card-detail?card_id=${id}`;
      },
      sell() {
        return `${API_BASE}/coupon-package/sell-card`;
      },
      sellOrders(condition) {
        const { page, key, couponCardId, startDate, endDate } = condition;
        return `${API_BASE}/coupon-package/card-order-list?keyword=${key}&card_id=${couponCardId}&coupon_start_date_begin=${startDate}&coupon_start_date_end=${endDate}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      sellOrderDetail(orderId) {
        return `${API_BASE}/coupon-package/card-order-detail?order_id=${orderId}`;
      },
      sellOrdersOfCustomer(customerId) {
        return `${API_BASE}/coupon-package/customer-card-list?customer_id=${customerId}`;
      },
    },
  },

  sms: {
    consumeList(condition) {
      const { page = 1, subType = '', status = '-10', startDate = '', endDate = '', planId = '', limit = api.config.limit } = condition;
      return `${API_BASE}/sms/consume-list?sub_type=${subType}&status=${status}&start_date=${startDate}&end_date=${endDate}&plan_id=${planId}&skip=${((Number(page) -
        1) || 0) * api.config.limit}&limit=${limit}`;
    },
    chargeList(condition) {
      const { page = 1 } = condition;
      return `${API_BASE}/sms/charge-list?skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}`;
    },
    groupSendList(condition) {
      const { page = 1, status = '-10' } = condition;
      return `${API_BASE}/sms/send-plan-list?status=${status}&skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}`;
    },
    chargeLevels() {
      return `${API_BASE}/sms/get-charge-level`;
    },
    getConfigs() {
      return `${API_BASE}/sms/get-config`;
    },
    editConfigs() {
      return `${API_BASE}/sms/edit-config`;
    },

    sendableCustomerCount() {
      return `${API_BASE}/sms/customer-count`;
    },
    sendBatch() {
      return `${API_BASE}/sms/send-batch-template-sms`;
    },

    template: {
      list(condition) {
        const { page = 1, startDate = '', status = '-10' } = condition;
        return `${API_BASE}/sms/template-list?create_start_date=${startDate}&status=${status}&skip=${(page -
          1) * api.config.limit}&limit=${api.config.limit}`;
      },
      add() {
        return `${API_BASE}/sms/create-template`;
      },
      edit() {
        return `${API_BASE}/sms/edit-template`;
      },
      detail(id) {
        return `${API_BASE}/sms/template-detail?template_id=${id}`;
      },

      customerCount() {
        return `${API_BASE}/sms/coupon-package-customer-count`;
      },
    },
  },
};

export default api;
