import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN'; // antd 默认为英文， 这里改为中文
import 'nprogress/nprogress.css';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import '../../configs/login-info-checker';
import configureStore from '../../store/configureStore';
import AuthUtil from '../../utils/AuthUtil';
import server from '../../utils/server';
import Root from './Root';
/**
 * ## States
 * defines initial state
 *
 */
import AuthInitialState from '../../reducers/auth/authInitialState';
import ActivityInitialState from '../../reducers/activity/activityInitialState';

const packageFile = require('../../../package.json');

message.config({ maxCount: 1 });

/**
 *
 * ## Initial state
 * Create instances for the keys of each structure in App
 * @returns {Object} object with 4 keys
 */
function getInitialState() {
  return {
    auth: new AuthInitialState(),
    activity: new ActivityInitialState(),
  };
}

window.env = module.hot ? 'dev' : 'dist';

const shuidaoURL = 'https://api.shuidao.com';
const yunbed = 'https://api.shuidao.yunbed.com';
const userInfo = AuthUtil.getLoginUserInfo();
let releaseState = 'development';
if (window.env === 'dist') {
  if (window.baseURL === shuidaoURL) {
    releaseState = 'production';
  } else if (window.baseURL === yunbed) {
    releaseState = 'testflight';
  } else {
    releaseState = 'dev1';
  }
}
server.businessModule = 'daotian';
const BugsnagClient = bugsnag({
  apiKey: 'df5c804812af2b8a4c376c5cc5478559',
  appVersion: packageFile.version,
  releaseStage: releaseState,
  user: {
    id: userInfo.id,
    name: userInfo.name,
    email: userInfo.phone,
  },
});
BugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = BugsnagClient.getPlugin('react');

const beforeSend = (report) => {
  report.severity = 'error';
};

const store = configureStore(getInitialState());
render(
  <ErrorBoundary beforeSend={beforeSend}>
    <ConfigProvider locale={zhCN}>
      <Root store={store} />
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById('app_daotian'),
);

if (module.hot) {
  module.hot.accept();
}
