import React from 'react';
import { Modal } from 'antd';
import { ScanOutlined } from '@ant-design/icons';

import BaseModal from '../../components/base/BaseModal';

export default class ApplyToUse extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal() {
    this.setState({ visible: true });
    try {
     // _czc.push(['_trackEvent', '申请试用', 'apply_to_use_daotian']);
    } catch (e) {
    }
  }

  render() {
    const { visible } = this.state;

    return (
      <div>
        <a onClick={this.showModal}>申请试用</a>

        <Modal
          title={<span><ScanOutlined /> 请使用微信扫码，申请试用</span>}
          visible={visible}
          footer={null}
          onCancel={this.hideModal}
        >
          <div className="pl15">
            <img src={require('../../images/daotian/weapp_repair_register.png')} alt="门店注册" />
          </div>
        </Modal>
      </div>
    );
  }
}
