import React from 'react';
import { Modal } from 'antd';

require('./style.less');

export default class DownloadGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      title: '',
    };

    [
      'onCancel',
      'handleDownloadGoogle',
    ].forEach(method => this[method] = this[method].bind(this));
  }

  componentWillMount() {
    const explorer = window.navigator.userAgent;
    // 判断
    if (explorer.indexOf('Chrome') > -1) {
      const re = /chrome\/[\d.]+/gi;
      const info = explorer.match(re);
      const version = info[0].replace('Chrome/', '');
      const v = version.substr(0, 2);
      if (Number(v) < 49) {
        this.setState({
          visible: true,
          title: '您当前浏览器版本过于低，会影响部分功能使用，请前往谷歌官网下载新版本浏览器！',
        });
      }
    } else {
      this.setState({
        visible: true,
        title: '您当前使用非谷歌浏览器，会影响功能使用体验，请前往谷歌官网下载新版本浏览器',
      });
    }
  }

  handleDownloadGoogle() {
    window.location.href = 'https://www.google.cn/chrome/';
  }

  onCancel() {
    this.setState({ visible: false });
  }

  render() {
    const { visible, title } = this.state;

    return (
      <Modal
        title="提示"
        visible={visible}
        onCancel={this.onCancel}
        maskClosable={false}
        okText="去下载"
        onOk={this.handleDownloadGoogle}
      >
        <p>{title}</p>
      </Modal>
    );
  }
}
