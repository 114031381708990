import moment from 'moment';

const now = new Date();

export default class DateUtil {
  static pattern = {
    date: 'YYYY-MM-DD HH:mm:ss',
    minute: 'YYYY-MM-DD HH:mm',
    hour: 'YYYY-MM-DD HH:mm',
    day: 'YYYY-MM-DD',
    dayCN: 'YYYY年MM月DD日',
    month: 'YYYY-MM',
    HHmm: 'HH:mm',
  };

  static format(date, pattern = this.pattern.date) {
    if (!date) {
      return '';
    }
    return moment(date).format(pattern);
  }

  /**
   * 日期格式化
   * @param date
   * @return {string}
   */
  static date(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.date);
  }

  static minute(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.minute);
  }

  static hour(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.hour);
  }

  static day(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.day);
  }

  static dayCN(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.dayCN);
  }

  static month(date) {
    if (!date) {
      return '';
    }
    return moment(date).format(DateUtil.pattern.month);
  }

  static time(date, format) {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }

  static getYesterdayEndpoint() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() -
      1, 23, 59, 59);
  }

  static getCurrentDate() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
  }

  static getCurrentWeekStart() {
    return moment().startOf('week').toDate();
  }

  static getCurrentMonthStart() {
    return moment().startOf('month').toDate();
  }

  static getCurrentYearStart() {
    return moment().startOf('year').toDate();
  }

  static getStartDayByPeriod(type) {
    let startDate = DateUtil.getCurrentDate();
    if (type === 'week') {
      startDate = DateUtil.getCurrentWeekStart();
    } else if (type === 'month') {
      startDate = DateUtil.getCurrentMonthStart();
    } else if (type === 'year') {
      startDate = DateUtil.getCurrentYearStart();
    }
    return DateUtil.day(startDate);
  }

  static getLatestWeekStart() {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
  }

  // 前十天
  static getLatestTenDay() {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 9, 0, 0, 0);
  }

  static getLatestFifteenDay() {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14, 0, 0, 0);
  }

  static getLatestSixtyDay() {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 61, 0, 0, 0);
  }

  static getLatestMonthStart() {
    return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1, 0, 0, 0);
  }

  static getLatestTwoYearsStart() {
    return new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), 0, 0, 0);
  }

  /**
   * 获取moment类型的数据
   * @param dateString 需要转换的日期,默认当天
   * @param isInit 是否初始化，如果传递的参数为'0000-00-00'，初始化为当天
   * @returns {*|moment.Moment}
   */
  static getMomentDate(dateString = new Date(), isInit = true) {
    // 处理无效日期，否则在初始化DatePicker时报错
    if (!dateString || (typeof dateString === 'string' && dateString.indexOf('0000') > -1)) {
      if (isInit) {
        dateString = new Date();
      } else {
        return null;
      }
    }
    return moment(dateString, DateUtil.pattern.date);
  }

  // 获取近一个月的开始时间
  static getMomentDateAMonthStart(date = new Date(now.getFullYear(), now.getMonth() -
    1, now.getDate(), 0, 0, 0)) {
    return moment(date, DateUtil.pattern.date);
  }

  // 获取近一个月的当前(结束)时间
  static getMomentDateAMonthEnd(date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)) {
    return moment(date, DateUtil.pattern.date);
  }

  static getMomentMonth(month = new Date()) {
    return moment(month, DateUtil.pattern.month);
  }

  static getMomentDay(day = new Date()) {
    return moment(day, DateUtil.pattern.day);
  }

  static getMomentHHmm(HHmm = new Date()) {
    return moment(HHmm, DateUtil.pattern.HHmm);
  }

  /**
   * 从当前时间计算，前一个月
   * @param date 非自然月
   * @return {Moment}
   */
  static getMomentPreviousMonth(date = new Date(
    now.getFullYear(), now.getMonth(), now.getDate() - 30,
  )) {
    return moment(date, DateUtil.pattern.day);
  }

  /**
   *  从当前时间计算，后一个月
   * @param date 非自然月
   * @return {Moment}
   */
  static getMomentNextMonth(date = new Date(
    now.getFullYear(), now.getMonth(), now.getDate() + 30,
  )) {
    return moment(date, DateUtil.pattern.day);
  }

  /**
   * 计算有效时间
   * @param Date date 格式为'yyyy-MM-dd'
   * @return {Number}
   */
  static calculateExpireDays(date) {
    if (!date || date === '0000-00-00') {
      return 0;
    }

    const expireDate = Date.parse(new Date(date));
    const currentDate = new Date().getTime();
    const expireDateRange = expireDate - currentDate;

    return parseInt(expireDateRange / 1000 / 60 / 60 / 24, 10);
  }

  /**
   * 格式化输出时间    去除yyyy-mm-dd hh-mm-ss 中和 ss
   * @param value
   * @returns {string}
   */
  static getFormatTime(value) {
    if (!value) {
      return '';
    }
    const time = value.split(':');
    // let removeYearTime = time.splice(1, time.length - 1).join('-');
    // time = removeYearTime.split(':');
    return time.splice(0, time.length - 1).join(':');
  }

  /**
   *将字符串日期格式格式化未Date类型，主要兼容safari
   * @param dateStr
   * @returns {Date}
   */
  static getDate(dateStr) {
    if (!dateStr) {
      return '';
    }
    const dateArr = dateStr.split(/[- : \/]/);
    if (!!dateArr[3]) {
      return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
    }
    return new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
  }

  /**
   * filter invalid date string and replace with '--'
   * @param date
   * @returns { string }
   */
  static filterEmptyDate(date) {
    if (!date) {
      return '';
    }

    if (date.indexOf('0000') > -1) {
      return '--';
    }

    if (date.indexOf('9999') > -1) {
      return '永久有效';
    }

    return date;
  }

  /**
   * 时间转换成距离现在有多久，如几分钟前、几小时前
   * @param ctime
   * @returns {Date}
   */
  static timeFromNow(ctime) {
    if (!ctime) {
      return '';
    }
    const now = Date.parse(new Date()) / 1000;
    let time = '';
    if (ctime) {
      time = new Date(ctime.replace(/-/g, '/')).getTime() / 1000;
    }
    const limit = now - time;
    let content = '';
    if (limit < 60) {
      content = '刚刚';
    } else if (limit >= 60 && limit < 3600) {
      content = `${Math.floor(limit / 60)}分钟前`;
    } else if (limit >= 3600 && limit < 86400) {
      content = `${Math.floor(limit / 3600)}小时前`;
    } else if (limit > 86400 && limit < 2592000) {
      content = `${Math.floor(limit / 86400)}天前`;
    } else if (limit >= 2592000 && limit < 31104000) {
      content = `${Math.floor(limit / 2592000)}个月前`;
    } else {
      content = '很久前';
    }
    return content;
  }

  // 根据时间戳获取时间
  static getTimeDate(time) {
    if (Number(time) === 0) {
      return '--';
    }
    const timeStr = new Date(parseInt(time) * 1000);
    const year = timeStr.getFullYear();
    const month = timeStr.getMonth() + 1;
    const date = timeStr.getDate();
    const hour = timeStr.getHours();
    const minute = timeStr.getMinutes();
    const second = timeStr.getSeconds();
    return `${year}-${month > 9 ? month : `0${month}`}-${date > 9 ? date : `0${date}`} ${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`;
  }

  // 日期类型转 字符串 new Date()
  static getDateToStr(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dateNum = date.getDate();
    return `${year}-${month > 9 ? month : `0${month}`}-${dateNum > 9 ? dateNum : `0${dateNum}`}`;
  }

  static rangeStr(start, end, val) {
    const result = [];
    for (let i = start; i < end; i++) {
      if (val > i) {
        result.push(i);
      }
    }
    return result;
  }

  static rangeEnd(start, end, val) {
    const result = [];
    for (let i = start; i < end; i++) {
      if (i >= val) {
        result.push(i);
      }
    }
    return result;
  }
}
