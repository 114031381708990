import _ from 'lodash';
import server from './server';

import apiDaotian from '../configs/api-daotian';
import apiDealer from '../configs/api-dealer';

/**
 * 权限校验工具类
 * 1. 登录用户信息
 * 2. 功能访问权限检查
 */
export default class AuthUtil {
  /**
   * check userInfo and update everyday
   * update the repair-shop info
   * @param url get api url
   */
  static checkLoginUserInfo(url) {
    if (!url) {
      return;
    }

    const lastUpdateMinute = localStorage.getItem('last_update');
    const lastUpdateDay = localStorage.getItem('last_update_day');
    const date = new Date();
    const hour = date.getHours();
    const today = date.getDate().toString();
    const minute = (hour * 60) + date.getMinutes();
    if (minute > Number(lastUpdateMinute) + 30 || lastUpdateDay !== today) {
      server.get(url, (data) => {
        const { user } = data.res;
        if (user && Object.keys(user).length > 0) {
          user.uid = user._id;
          localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
          localStorage.setItem('last_update_day', today);
          localStorage.setItem('last_update', minute);
        }
      });

      // update the daotian repair-shop info
      if (url.indexOf('daotian_web') > -1) {
        server.get(apiDaotian.company.detail(), (data) => {
          localStorage.setItem('STORE_INFO', window.btoa(window.encodeURIComponent(JSON.stringify(data.res.company))));
        });
      }
    }
  }

  static updateLoginUserInfo(url) {
    if (!url) {
      return;
    }

    server.get(url, (data) => {
      const { user } = data.res;
      localStorage.removeItem('USER_SESSION');
      if (user && Object.keys(user).length > 0) {
        user.uid = user._id;
        localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
      }
    });
  }

  /**
   * get standard login user info
   * user properties are formed by camelCase
   * @returns {object}
   */
  static getLoginUserInfo() {
    let userSessionStr = localStorage.getItem('USER_SESSION');
    userSessionStr = userSessionStr ? window.decodeURIComponent(window.atob(userSessionStr)) : '';

    const userInfo = userSessionStr ? JSON.parse(userSessionStr) : {};

    const standardUserInfo = {};
    for (let prop in userInfo) {
      if (typeof userInfo[prop] === 'number') {
        standardUserInfo[_.camelCase(prop)] = `${userInfo[prop]}`; // convert to string
      } else {
        standardUserInfo[_.camelCase(prop)] = userInfo[prop];
      }
    }

    return standardUserInfo;
  }

  static getLoginUserStoreInfo() {
    let storeInfoStr = localStorage.getItem('STORE_INFO');
    storeInfoStr = storeInfoStr ? window.decodeURIComponent(window.atob(storeInfoStr)) : '';

    const storeInfo = storeInfoStr ? JSON.parse(storeInfoStr) : {};

    const standardStoreInfo = {};
    for (let prop in storeInfo) {
      standardStoreInfo[_.camelCase(prop)] = `${storeInfo[prop]}`; // convert to string
    }

    return standardStoreInfo;
  }

  static getUserPermissions() {
    return localStorage.getItem('user_permission')
      ? window.decodeURIComponent(window.atob(localStorage.getItem('user_permission')))
      : '[]';
  }

  static checkPermission(path) {
    return new Promise((resolve, reject) => {
      if (AuthUtil.isSuperAdministrator() || AuthUtil.isChainAdministrator() ||
        AuthUtil.isStoreAdmin()) {
        resolve(true);
      } else {
        server.get(apiDaotian.user.checkPermission(path), () => {
          resolve(true);
        }, () => {
          resolve(false);
        });
      }
    });
  }

  static checkDealerPermission(path) {
    return new Promise((resolve, reject) => {
      if (AuthUtil.isSuperAdministrator() || AuthUtil.isChainAdministrator() ||
        AuthUtil.isStoreAdmin()) {
        resolve(true);
      } else {
        server.get(apiDealer.user.checkPermission(path), () => {
          resolve(true);
        }, () => {
          reject(false);
        });
      }
    });
  }

  static checkDealerPermission(path) {
    return new Promise((resolve, reject) => {
      if (AuthUtil.isSuperAdministrator() || AuthUtil.isChainAdministrator() ||
        AuthUtil.isStoreAdmin()) {
        resolve(true);
      } else {
        server.get(apiDealer.user.checkPermission(path), () => {
          resolve(true);
        }, () => {
          reject(false);
        });
      }
    });
  }

  static isLogin() {
    return !!AuthUtil.getLoginUserInfo().uid;
  }

  // 总公司
  static isHeadquarters() {
    return AuthUtil.getLoginUserInfo().companyId === '1';
  }

  // 连锁店管理员
  static isChainAdministrator() {
    return AuthUtil.getLoginUserInfo().userType === '1';
  }

  // 区域管理员
  static isRegionAdministrator() {
    return AuthUtil.getLoginUserInfo().userType === '2';
  }

  // 新能源账号
  static isNewEnergy() {
    return AuthUtil.getLoginUserInfo().department === '4';
  }

  // 新能源管理员
  static isNewEnergyAdmin() {
    return AuthUtil.getLoginUserInfo().department === '4' && AuthUtil.getLoginUserInfo().role !== '402';
  }

  // 总公司管理员
  static isSuperAdministrator() {
    return AuthUtil.getLoginUserInfo().userType === '3';
  }

  // 总公司普通职员
  static isHeadquarterMember() {
    return AuthUtil.getLoginUserInfo().userType === '4';
  }

  // 总公司-管理
  static isHeadquarterManager() {
    return AuthUtil.getLoginUserInfo().userType === '4' &&
      AuthUtil.getLoginUserInfo().department === '1';
  }

  // 总公司-分控
  static isHeadquarterRisk() {
    return AuthUtil.getLoginUserInfo().userType === '4' &&
      AuthUtil.getLoginUserInfo().department === '2';
  }

  // 总公司-财务
  static isHeadquarterFinance() {
    return AuthUtil.getLoginUserInfo().userType === '4' &&
      AuthUtil.getLoginUserInfo().department === '3';
  }

  // 优车-总经办-城市负责人
  static isYoucheRegionAdmin() {
    const userInfo = AuthUtil.getLoginUserInfo();
    return userInfo.department === '1' && userInfo.role === '101';
  }

  /**
   * * department
   * -2: 管理员
   *  0: 无权限
   *  1: 总经办
   *  2: 售前
   *  3: 售后
   */
  // 门店总经理
  static isStoreAdmin() {
    return AuthUtil.getLoginUserInfo().department === '1' &&
      AuthUtil.getLoginUserInfo().role === '100';
  }

  // 门店负责人
  static isDealerStoreAdmin() {
    return AuthUtil.getLoginUserInfo().department === '1' &&
      AuthUtil.getLoginUserInfo().role === '101';
  }
}
