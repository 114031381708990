const API_HOST = `${window.baseURL}/daotian_chain`;

const api = {
  config: {
    limit: 15,
    halfLimit: 10,
    skip: 0,
  },

  system: {
    getVerifyCode() {
      return `${API_HOST}/user/get-code`;
    },
    login() {
      return `${API_HOST}/user/login`;
    },
    logout() {
      return `${API_HOST}/user/logout`;
    },
    userInfo() {
      return `${API_HOST}/user/info`;
    },
    refressLogin() {
      return `${API_HOST}/user/refress-login`;
    },
  },

  user: {
    fullList(condition) {
      const { key = '', page = 1 } = condition;
      return `${API_HOST}/user/user-full-list?keyword=${key}&skip=${(page - 1) *
      api.config.limit}&limit=${api.config.limit}`;
    },
    listByDepartmentAndRoles(department, role) {
      return `${API_HOST}/user/user-list?department=${department}&role=${role}`;
    },
    new() {
      return `${API_HOST}/user/create`;
    },
    edit() {
      return `${API_HOST}/user/edit`;
    },
    fire() {
      return `${API_HOST}/user/fire`;
    },
    detail(userId) {
      return `${API_HOST}/user/detail?user_id=${userId}`;
    },
    fullDetail(userId) {
      return `${API_HOST}/user/user-full-detail?_id=${userId}`;
    },
    departmentRoles(department) {
      return `${API_HOST}/user/department-roles?department=${department}`;
    },
    departmentRolesAll() {
      return `${API_HOST}/user/all-department-roles`;
    },
  },

  // 连锁门店
  company: {
    list() {
      return `${API_HOST}/company/list`;
    },
  },

  presales: {
    purchaseSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-summary?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyPotentialCustomers(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-potential-summary-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyDeals(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-auto-deals-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyDealFails(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-fail-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dealFailTypes(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-fail-types?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    intentionSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-intentions?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    incomeSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/purchase-incomes?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
  },

  aftersales: {
    // 统计概要
    todaySummary(companyId) {
      return `${API_HOST}/statistic/maintain-today-summary?company_id=${companyId}`;
    },
    maintainSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-summary?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    incomeSummaryByTypes(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-type-summary?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    payTypesSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-income-pay-types?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    couponCardsSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-coupon-cards?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    financeIncomePayTypesSummary(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-finance-income-pay-types?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },

    // 按天统计
    dailyMaintainIncomes(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-incomes-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyMaintainCounts(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-count-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyPartPurchase(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-auto-part-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyIncomeByTypes(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-type-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
    dailyFinance(companyId, startDate, endDate) {
      return `${API_HOST}/statistic/maintain-finance-days?company_id=${companyId}&start_date=${startDate}&end_date=${endDate}`;
    },
  },

  finance: {
    monthSummary(companyId, month) {
      return `${API_HOST}/financial/financial-summary?company_id=${companyId}&month=${month}`;
    },
  },

  workOrder: {
    list(condition) {
      const { page = 1, key = '', status = '-2', payStatus = '-2', type = '', companyId = '' } = condition;
      return `${API_HOST}/maintain/intention-list?key=${key}&status=${status}&pay_status=${payStatus}&company_id=${companyId}&type=${type}&skip=${(page -
        1) * api.config.limit}&limit=${api.config.limit}`;
    },

    getParts(id) {
      return `${API_HOST}/maintain/intention-part-list?intention_id=${id}`;
    },

    getItems(id) {
      return `${API_HOST}/maintain/intention-item-list?intention_id=${id}`;
    },

    detail(id) {
      return `${API_HOST}/maintain/intention-detail?_id=${id}`;
    },
  },

  auto: {
    detail(autoId, customerId = '') {
      return `${API_HOST}/auto/auto-detail?auto_id=${autoId}&customer_id=${customerId}`;
    },
  },

  customer: {
    detail(customerId) {
      return `${API_HOST}/customer/info?customer_id=${customerId}`;
    },
    getCustomerUnpayAmount(customerId) {
      return `${API_HOST}/customer/total-unpay-amount?customer_id=${customerId}`;
    },

    getCustomerPayLog(id, autoId) {
      return `${API_HOST}/maintain/intention-pay-log?_id=${id}&auto_id=${autoId}`;
    },
  },
};

export default api;
