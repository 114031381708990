const { Record } = require('immutable');

const InitialState = Record({
  isFetching: false,
  page: 1,
  list: [],
  total: 0,

  autoBrandId: '',
  autoSeriesId: '请选择',
  autoTypeId: '请选择',
  guidePrice: '',
  isCustomeAuto: '-1',

  autoBrands: [],
  autoSeries: [],
  autoTypes: [],
  autoTypeDetail: {},

  autoPicsList: [],
  introducePicsList: [],
});

export default InitialState;

