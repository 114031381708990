const InitialState = require('./modelsInitialState').default;

const {
  GET_MODELS_LIST_REQUEST,
  GET_MODELS_LIST_SUCCESS,
  GET_MODELS_LIST_FAILURE,

  GET_MODELS_AUTO_DETAIL_REQUEST,
  GET_MODELS_AUTO_DETAIL_SUCCESS,

  GET_MODELS_AUTOBRANDS_REQUEST,
  GET_MODELS_AUTOBRANDS_SUCCESS,

  GET_MODELS_AUTOSERIES_REQUEST,
  GET_MODELS_AUTOSERIES_SUCCESS,

  GET_MODELS_AUTOTYPES_REQUEST,
  GET_MODELS_AUTOTYPES_SUCCESS,

  SET_MODELS_PAGE,
  SET_MODELS_AUTO_BRANDID,
  SET_MODELS_AUTO_SERIESID,
  SET_MODELS_AUTO_TYPEID,
  SET_MODELS_GUIDE_PRICE,
  SET_MODELS_CUSTOME_AUTO,
} = require('../constants').default;

const initialState = new InitialState;

export default function activityReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case GET_MODELS_LIST_REQUEST:
      return state.set('isFetching', true);
    case GET_MODELS_LIST_SUCCESS:
      const { list, total } = action.payload;
      return state.set('list', list).set('isFetching', false).set('total', total);
    case GET_MODELS_LIST_FAILURE:
      return state.set('isFetching', false);

    case GET_MODELS_AUTOBRANDS_REQUEST:
      break;
    case GET_MODELS_AUTOBRANDS_SUCCESS:
      return state.set('autoBrands', action.payload);
    case GET_MODELS_AUTO_DETAIL_REQUEST:
      break;
    case GET_MODELS_AUTO_DETAIL_SUCCESS:
      return state
        .set('autoTypeDetail', action.payload.detail)
        .set('autoPicsList', action.payload.auto_type_pics_list)
        .set('introducePicsList', action.payload.auto_type_highlight_list);
    case GET_MODELS_AUTOSERIES_REQUEST:
      break;
    case GET_MODELS_AUTOSERIES_SUCCESS:
      return state.set('autoSeries', action.payload);
    case GET_MODELS_AUTOTYPES_REQUEST:
      break;
    case GET_MODELS_AUTOTYPES_SUCCESS:
      return state.set('autoTypes', action.payload);

    case SET_MODELS_PAGE:
      return state.set('page', action.payload);
    case SET_MODELS_AUTO_BRANDID:
      return state.set('autoBrandId', action.payload).set('page', 1);
    case SET_MODELS_AUTO_SERIESID:
      return state.set('autoSeriesId', action.payload).set('page', 1);
    case SET_MODELS_AUTO_TYPEID:
      return state.set('autoTypeId', action.payload).set('page', 1);
    case SET_MODELS_GUIDE_PRICE:
      return state.set('guidePrice', action.payload).set('page', 1);
    case SET_MODELS_CUSTOME_AUTO:
      return state.set('isCustomeAuto', action.payload).set('page', 1);
  }

  return state;
}
