const InitialState = require('./intentionInitialState').default;

const {
  SET_INTENTION_KEY,
  SET_INTENTION_BIZTYPE,

  GET_INTENTIONS_REQUEST,
  GET_INTENTIONS_SUCCESS,
  GET_INTENTIONS_FAILURE,

  SET_INTENTIONS_PAGE,
} = require('../../constants').default;

const initialState = new InitialState;

export default function activityReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case GET_INTENTIONS_REQUEST:
      return state.set('isFetching', true);
    case GET_INTENTIONS_SUCCESS:
      const { list, total } = action.payload;
      return state.set('isFetching', false).set('list', list).set('total', parseInt(total, 10));
    case GET_INTENTIONS_FAILURE:
      return state.set('isFetching', false);

    case SET_INTENTION_BIZTYPE:
      return state.set('bizType', action.payload).set('page', 1);
    case SET_INTENTION_KEY:
      return state.set('searchKey', action.payload).set('page', 1);
    case SET_INTENTIONS_PAGE:
      return state.set('page', action.payload);
  }

  return state;
}
