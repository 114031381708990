import DateUtil from '../../../utils/DateUtil';

const { Record } = require('immutable');

const InitialState = Record({
  isFetching: false,
  page: 1,
  list: [],
  total: 0,
  error: null,

  searchKey: '',
  province: '',
  cityId: '',
  country: '',
  companyId: '0',
  startDate: DateUtil.day(DateUtil.getLatestMonthStart()),
  endDate: DateUtil.day(new Date()),
  productId: '0',
  financingType: '0',
  options: [],
  productList: [],
  companyList: [],
  bizType: '-1',
});

export default InitialState;

