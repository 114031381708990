const InitialState = require('./newInitialState').default;

const {
  GET_NEW_AUTOBRANDS_REQUEST,
  GET_NEW_AUTOBRANDS_SUCCESS,

  GET_NEW_AUTOSERIES_REQUEST,
  GET_NEW_AUTOSERIES_SUCCESS,

  GET_NEW_AUTOTYPES_REQUEST,
  GET_NEW_AUTOTYPES_SUCCESS,

  SET_NEW_AUTO_BRANDID,
  SET_NEW_AUTO_SERIESID,
  SET_NEW_AUTO_TYPEID,

  GET_NEW_PROGRAMMES_REQUEST,
  GET_NEW_PROGRAMMES_SUCCESS,

  GET_SALES_LIST_REQUEST,
  GET_SALES_LIST_SUCCESS,

  GET_NEW_TEAMS_REQUEST,
  GET_NEW_TEAMS_SUCCESS,

  GET_NEW_YOUCHE_USER_REQUEST,
  GET_NEW_YOUCHE_USER_SUCCESS,

  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
} = require('../../constants').default;

const initialState = new InitialState;

export default function activityReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {

    case GET_NEW_AUTOBRANDS_REQUEST:
      break;
    case GET_NEW_AUTOBRANDS_SUCCESS:
      return state.set('autoBrands', action.payload);
    case GET_NEW_AUTOSERIES_REQUEST:
      break;
    case GET_NEW_AUTOSERIES_SUCCESS:
      return state.set('autoSeries', action.payload);
    case GET_NEW_AUTOTYPES_REQUEST:
      break;
    case GET_NEW_AUTOTYPES_SUCCESS:
      return state.set('autoTypes', action.payload);
    case SET_NEW_AUTO_BRANDID:
      return state.set('autoBrandId', action.payload);
    case SET_NEW_AUTO_SERIESID:
      return state.set('autoSeriesId', action.payload);
    case SET_NEW_AUTO_TYPEID:
      return state.set('autoTypeId', action.payload);
    case GET_NEW_PROGRAMMES_REQUEST:
      break;
    case  GET_NEW_PROGRAMMES_SUCCESS:
      return state.set('productsList', action.payload);
    case GET_SALES_LIST_REQUEST:
      break;
    case GET_SALES_LIST_SUCCESS:
      return state.set('salesList', action.payload);

    case GET_NEW_YOUCHE_USER_REQUEST:
      break;
    case GET_NEW_YOUCHE_USER_SUCCESS:
      return state.set('userList', action.payload);
    case GET_COMPANY_LIST_REQUEST:
      break;
    case GET_COMPANY_LIST_SUCCESS:
      return state.set('companyList', action.payload);
    case GET_NEW_TEAMS_REQUEST:
      break;
    case GET_NEW_TEAMS_SUCCESS:
      return state.set('teamList', action.payload);
  }

  return state;
}
