import React, { Component } from 'react';
import { Popover } from 'antd';


import QRCode from 'qrcode.react';
import className from 'classnames';

import AuthUtil from '../../utils/AuthUtil';

require('../../styles/help.less');

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceVisible: false,
      returnVisible: false,
    };

    [
      'handleServiceMouseEnter',
      'handleServiceMouseLeave',
      'handleReturnMouseEnter',
      'handleReturnMouseLeave',
    ].map(method => this[method] = this[method].bind(this));
  }

  handleServiceMouseEnter() {
    this.setState({ serviceVisible: true });
  }

  handleServiceMouseLeave() {
    this.setState({ serviceVisible: false });
  }

  handleReturnMouseEnter() {
    this.setState({ returnVisible: true });
  }

  handleReturnMouseLeave() {
    this.setState({ returnVisible: false });
  }

  render() {
    const { serviceVisible } = this.state;
    const { companyId, operationName, operationPhone } = AuthUtil.getLoginUserInfo();

    const contentVisibility = className({
      'help-content': !(String(companyId) === '1'),
      hide: String(companyId) === '1',
    });

    const content = (
      <div className="help" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ paddingBottom: 5 }}>微信扫码</p>
        <QRCode size={128} value="https://work.weixin.qq.com/kfid/kfcb53c717240e77c7e" />
        <p style={{ fontSize: 13, paddingTop: 5 }}>周一到周六 9:00-19:00</p>
      </div>
    );

    const contentQrcode = (
      <div>
        <div className="canvas no-print qr-popover-home">
          <QRCode size={128} value={`${window.h5Host}/h5/app-download-tob.html`} />
          <p className="padding-top-5">水稻管家-门店版</p>
        </div>

        <div className="canvas no-print qr-popover-home">
          <QRCode size={128} value={`${window.h5Host}/h5/app-download-toc.html`} />
          <p className="padding-top-5">水稻汽车-客户版</p>
        </div>
      </div>
    );

    return (
      <div className={contentVisibility}>
        <div className="help-link">
          <Popover
            placement="leftTop"
            title={null}
            content={contentQrcode}
            trigger="click"
            overlayStyle={{ width: '353px', height: '190px' }}
            overlayClassName="white"
          >
            <div className="link qr-link">
              <div className="qrcode" />
            </div>
          </Popover>

          <Popover
            placement="leftTop"
            title={null}
            content={content}
            trigger="click"
            overlayStyle={{ width: '190px', height: '190px' }}
            overlayClassName="white"
          >
            <div
              className="link"
              onMouseEnter={this.handleServiceMouseEnter}
              onMouseLeave={this.handleServiceMouseLeave}
            >
              <div className={!!serviceVisible ? 'service' : 'hide'}>
                <p>客服</p>
                <p>支持</p>
              </div>

              <div className={!serviceVisible ? 'person' : 'hide'} />
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}
