const { Record } = require('immutable');

const InitialState = Record({
  isFetching: false,
  page: 1,
  list: [],
  total: 0,
  error: null,

  searchKey: '',
  status: '-20',
  bizType: '-1',
});

export default InitialState;

