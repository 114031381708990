const InitialState = require('./noticeInitialState').default;

const {
  GET_NOTICE_LIST_REQUEST,
  GET_NOTICE_LIST_SUCCESS,
} = require('../constants').default;

const initialState = new InitialState;

export default function activityReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case GET_NOTICE_LIST_REQUEST:
    case GET_NOTICE_LIST_SUCCESS:
      return state.set('list', action.payload);
  }

  return state;
}
