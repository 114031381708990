import React from 'react';
import { Redirect } from 'react-router-dom';

import LazyRoute from 'lazy-route';
import AuthUtil from '../../utils/AuthUtil';

const routers = [
  {
    path: '/',
    breadcrumbName: '首页/',
    exact: true,
    render: () => (AuthUtil.isLogin() ? <Redirect to="/home" /> : <Redirect to="/login" />),
  }, {
    path: '/home',
    exact: true,
    breadcrumbName: '首页/',
    render: props => <LazyRoute {...props} component={import('./Home')} />,
  }, {
    path: '/customer/detail/:id',
    breadcrumbName: '车辆管理/客户详情',
    exact: true,
    render: props => (
      <LazyRoute {...props} component={import('./customer/Detail')} />
    ),
  }, {
    path: '/auto/detail/:id',
    breadcrumbName: '车辆管理/车辆详情',
    exact: true,
    render: props => (
      <LazyRoute {...props} component={import('./auto/Detail')} />
    ),
  },

  // 1. 售前
  {
    path: '/presales/potential/index',
    breadcrumbName: '销售/意向客户/列表',
    exact: true,
    render: props => (
      <LazyRoute {...props} component={import('./presales/potential/List')} />
    ),
  }, {
    breadcrumbName: '销售/成交车辆/列表',
    path: '/presales/deal/index',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/新增',
    path: '/presales/deal/new',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/新增',
    path: '/presales/deal/new/:customerId/:intentionId',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/新增',
    path: '/presales/deal/new/:customerId/:autoId/:intentionId',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/详情',
    path: '/presales/deal/detail',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/详情',
    path: '/presales/deal/detail/:autoDealId/:customerId/:intentionId',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '销售/成交车辆/详情',
    path: '/presales/deal/detail/:autoDealId/:customerId/:autoId/:intentionId',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/New')} />
    ),
  }, {
    breadcrumbName: '销售/成交车辆/结算单',
    exact: true,
    path: '/presales/deal/clearing',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/Clearing')} />
    ),
  }, {
    breadcrumbName: '销售/成交车辆/结算单',
    path: '/presales/deal/clearing/:id',
    render: props => (
      <LazyRoute {...props} component={import('./presales/deal/Clearing')} />
    ),
  },

  // 2.售后
  {
    breadcrumbName: '售后/工单管理/列表',
    path: '/aftersales/work-order/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/新增',
    path: '/aftersales/work-order/new',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/新增',
    path: '/aftersales/work-order/new/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/新增',
    path: '/aftersales/work-order/new/:customerId/:autoId',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/编辑',
    path: '/aftersales/work-order/edit',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/编辑',
    path: '/aftersales/work-order/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/编辑',
    path: '/aftersales/work-order/edit/:id/:autoId',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/编辑',
    path: '/aftersales/work-order/edit/:id/:customerId/:autoId',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/工单管理/详情',
    path: '/aftersales/work-order/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/work-order/Edit')} />
    ),
  }, {
    breadcrumbName: '售后/企业工单管理',
    path: '/aftersales/enterprise-work-order/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/enterprise-work-order/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/企业工单管理/新增',
    path: '/aftersales/enterprise-work-order/new',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/enterprise-work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/企业工单管理/编辑',
    path: '/aftersales/enterprise-work-order/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/enterprise-work-order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/企业工单管理/详情',
    path: '/aftersales/enterprise-work-order/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/enterprise-work-order/Edit')} />
    ),
  }, {
    breadcrumbName: '售后/销售单管理',
    path: '/aftersales/part-sale/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/part-sale/List')} />
    ),
  }, {
    breadcrumbName: '售后/销售管理/创建销售单',
    path: '/aftersales/part-sale/new',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/part-sale/New')} />
    ),
  }, {
    breadcrumbName: '售后/销售管理/编辑销售单',
    path: '/aftersales/part-sale/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/part-sale/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/耗材领用/列表',
    path: '/aftersales/consumptive-material/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/consumptive-material/List')} />
    ),
  }, {
    breadcrumbName: '售后/耗材领用/列表',
    path: '/aftersales/consumptive-material/index/:consumptiveShow',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/consumptive-material/List')} />
    ),
  }, {
    breadcrumbName: '售后/会员管理',
    path: '/aftersales/member/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/member/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/车辆管理/列表',
    path: '/aftersales/auto/index',
    render: props => (
      <LazyRoute {...props} component={import('./auto/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '售后/车主管理/列表',
    path: '/aftersales/auto-customer/index',
    render: props => (
      <LazyRoute {...props} component={import('./auto-customer/List')} />
    ),
  }, {
    breadcrumbName: '售后/耗材领用/列表',
    path: '/aftersales/consumptive-material/index/:consumptiveShow',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/consumptive-material/List')} />
    ),
  }, {
    breadcrumbName: '维修项目/列表',
    path: '/maintain-item/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/maintain-item/List')} />
    ),
  }, {
    breadcrumbName: '售后/仓库预警/列表',
    path: '/aftersales/inventory-warn/index',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/inventory-warn/List')} />
    ),
  }, {
    breadcrumbName: '售后/评价管理/列表',
    path: '/aftersales/customer/score',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/comment/List')} />
    ),
  }, /* {
    breadcrumbName: '周报',
    path: '/weekly/new',
    render: props => (
      <LazyRoute {...props} component={import('./aftersales/weekly/New')} />
    ),
  }, */

  // 3.仓库
  {
    breadcrumbName: '仓库/配件/列表',
    path: '/warehouse/part/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/part/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/配件/列表',
    path: '/warehouse/part/detail',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/part/Detail')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/配件/列表',
    path: '/warehouse/part/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/part/Detail')} />
    ),
  }, {
    breadcrumbName: '仓库/配件/商城',
    path: '/warehouse/part/store',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/part/Store')} />
    ),
  }, {
    breadcrumbName: '仓库/配件分类管理',
    path: '/warehouse/category/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/category/List')} />
    ),
  }, {
    breadcrumbName: '仓库/分类及加价率',
    path: '/warehouse/markup-rate/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/markup-rate/List')} />
    ),
  }, {
    breadcrumbName: '仓库/采购单管理/列表',
    path: '/warehouse/purchase/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/采购单管理/采购开单',
    path: '/warehouse/purchase/new',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/New')} />
    ),
  }, {
    breadcrumbName: '仓库/采购单管理/采购开单',
    path: '/warehouse/purchase/new/:partId',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/采购单管理/采购单编辑',
    path: '/warehouse/purchase/edit',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/采购单管理/采购单编辑',
    path: '/warehouse/purchase/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/采购单管理/详情',
    path: '/warehouse/purchase/detail',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/Detail')} />
    ),
  }, {
    breadcrumbName: '仓库/采购单管理/详情',
    path: '/warehouse/purchase/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/Detail')} />
    ),
  }, {
    breadcrumbName: '仓库/退货单管理/列表',
    path: '/warehouse/purchase-reject/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/List')} />
    ),
  }, {
    breadcrumbName: '仓库/退货单管理/退货开单',
    path: '/warehouse/purchase-reject/new',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/退货单管理/退货单编辑',
    path: '/warehouse/purchase-reject/edit',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/退货单管理/退货单编辑',
    path: '/warehouse/purchase-reject/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/退货单管理/详情',
    path: '/warehouse/purchase-reject/detail',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/Detail')} />
    ),
  }, {
    breadcrumbName: '仓库/退货单管理/详情',
    path: '/warehouse/purchase-reject/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase-reject/Detail')} />
    ),
  }, {
    breadcrumbName: '仓库/盘点管理/列表',
    path: '/warehouse/stocktaking/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/List')} />
    ),
  }, {
    breadcrumbName: '仓库/盘点管理/盘点开单',
    path: '/warehouse/stocktaking/new',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/盘点管理/盘点单',
    path: '/warehouse/stocktaking/edit',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '仓库/盘点管理/盘点单',
    path: '/warehouse/stocktaking/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/Edit')} />
    ),
  }, {
    breadcrumbName: '仓库/盘点管理/审核单',
    exact: true,
    path: '/warehouse/stocktaking/auth',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/Auth')} />
    ),
  }, {
    breadcrumbName: '仓库/盘点管理/审核单',
    path: '/warehouse/stocktaking/auth/:id',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/stocktaking/Auth')} />
    ),
  }, {
    breadcrumbName: '仓库/出入库管理/列表',
    path: '/warehouse/logs/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/log/List')} />
    ),
  }, {
    breadcrumbName: '仓库/进货历史/列表',
    path: '/warehouse/part-entry-log/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/purchase/List')} />
    ),
  }, {
    breadcrumbName: '仓库/供应商/列表',
    path: '/warehouse/supplier/index',
    render: props => (
      <LazyRoute {...props} component={import('./warehouse/supplier/List')} />
    ),
  },

  // 4. 统计
  {
    exact: true,
    breadcrumbName: '报表/销售业务',
    path: '/dashboard/presales',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/presales/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '报表/售后业务',
    path: '/dashboard/aftersales',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/aftersales/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '报表/售后业务/营业额明细',
    path: '/dashboard/aftersales/turnover/:startTime/:endTime',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/TurnoverDetails')} />
    ),
  },

  // 5. 财务
  {
    exact: true,
    breadcrumbName: '财务管理/收款管理/列表',
    path: '/finance/receive-money-qrcode/income',
    render: props => (
      <LazyRoute {...props} component={import('./finance/receive-money-qrcode/income/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务管理/提现管理/列表',
    path: '/finance/receive-money-qrcode/withdraw',
    render: props => (
      <LazyRoute {...props} component={import('./finance/receive-money-qrcode/withdraw/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务管理/收支管理/列表',
    path: '/finance/expense/list',
    render: props => (
      <LazyRoute {...props} component={import('./finance/expense/List')} />
    ),
  }, {
    breadcrumbName: '财务管理/收支管理/列表',
    path: '/finance/expense/list/:incomeShow/:expenseShow',
    render: props => (
      <LazyRoute {...props} component={import('./finance/expense/List')} />
    ),
  }, {
    breadcrumbName: '财务管理/新车收入/列表',
    path: '/finance/presales-income/list',
    render: props => (
      <LazyRoute {...props} component={import('./finance/presales/IncomeList')} />
    ),
  }, {
    breadcrumbName: '财务管理/固定资产/列表',
    path: '/finance/fixed-assets/index',
    render: props => (
      <LazyRoute {...props} component={import('./finance/fixed-assets/List')} />
    ),
  }, {
    breadcrumbName: '财务管理/月报汇总',
    path: '/finance/monthly_report',
    render: props => (
      <LazyRoute {...props} component={import('./finance/MonthlyReport')} />
    ),
  },

  // 6.人事
  {
    breadcrumbName: '人事管理/员工管理/列表',
    path: '/personnel/user/list',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/user/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '人事管理/员工管理/详情',
    path: '/personnel/user/detail',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/user/Detail')} />
    ),
  }, {
    breadcrumbName: '人事管理/员工管理/详情',
    path: '/personnel/user/detail/:userId',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/user/Detail')} />
    ),
  }, {
    breadcrumbName: '人事管理/薪资管理/列表',
    path: '/personnel/salary/list',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/salary/List')} />
    ),
  }, {
    breadcrumbName: '人事管理/提成管理/列表',
    path: '/personnel/commission/list',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/commission/List')} />
    ),
  },

  // 7.营销
  {
    breadcrumbName: '营销/优惠券',
    path: '/marketing/coupon/list',
    render: props =>
      <LazyRoute {...props} component={import('./marketing/coupon/List')} />,
  }, {
    breadcrumbName: '营销/套餐卡管理/列表',
    path: '/marketing/coupon-card/list',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-card/List')} />
    ),
  }, {
    breadcrumbName: '营销/套餐卡管理/销售记录',
    path: '/marketing/coupon-card/orders',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-card-order/List')} />
    ),
  }, {
    breadcrumbName: '营销/营销活动/列表',
    path: '/marketing/activity/list',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/营销活动/新建',
    path: '/marketing/activity/new',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/营销活动/新建',
    path: '/marketing/activity/new/:couponId',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/营销活动/编辑',
    path: '/marketing/activity/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/营销活动/活动详情',
    path: '/marketing/activity/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity/Detail')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/领券活动/列表',
    path: '/marketing/coupon-activity/list',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-activity/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/领券活动/列表',
    path: '/marketing/coupon-activity/list/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-activity/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/领券活动/新建',
    path: '/marketing/coupon-activity/new',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/领券活动/编辑',
    path: '/marketing/coupon-activity/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/领券活动/详情',
    path: '/marketing/coupon-activity/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon-activity/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/助力砍价/活动管理',
    path: '/marketing/bargain/index',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/bargain/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/助力砍价/参与记录',
    path: '/marketing/bargain/index/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/bargain/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/助力砍价/创建活动',
    path: '/marketing/bargain/new',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/bargain/New')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/助力砍价/创建活动',
    path: '/marketing/bargain/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/bargain/New')} />
    ),
  },

  // 8. 提醒
  {
    exact: true,
    breadcrumbName: '提醒/续保提醒',
    path: '/remind/renewal',
    render: props => (
      <LazyRoute {...props} component={import('./remind/renewal/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '提醒/保养提醒',
    path: '/remind/maintain',
    render: props => (
      <LazyRoute {...props} component={import('./remind/maintain/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '提醒/年检提醒',
    path: '/remind/yearly-inspection',
    render: props => (
      <LazyRoute {...props} component={import('./remind/yearly-inspection/List')} />
    ),
  }, {
    breadcrumbName: '提醒/催收提醒',
    path: '/remind/debt',
    render: props => (
      <LazyRoute {...props} component={import('./remind/debt/List')} />
    ),
  }, {
    breadcrumbName: '提醒/套餐卡到期',
    path: '/remind/coupon-card',
    render: props => (
      <LazyRoute {...props} component={import('./remind/coupon-card/List')} />
    ),
  }, {
    breadcrumbName: '提醒/生日提醒',
    path: '/remind/birthday',
    render: props => (
      <LazyRoute {...props} component={import('./remind/birthday/List')} />
    ),
  }, {
    breadcrumbName: '提醒/其他回访',
    path: '/remind/common',
    render: props => (
      <LazyRoute {...props} component={import('./remind/common/List')} />
    ),
  }, {
    breadcrumbName: '设置/门店信息',
    path: '/company-setting',
    render: props => (
      <LazyRoute {...props} component={import('./setting/Setting')} />
    ),
  },

  // 9. 设置 注：短信功能暂时移动到营销模块，确定后移动代码
  {
    exact: true,
    breadcrumbName: '营销/短信推送',
    path: '/company/sms-manage',
    render: props => (
      <LazyRoute {...props} component={import('./setting/sms/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/短信推送',
    path: '/company/sms/:activeKey',
    render: props => (
      <LazyRoute {...props} component={import('./setting/sms/List')} />
    ),
  },

  // 10. error pages
  {
    breadcrumbName: '403/',
    path: '/permission-403',
    render: props => <LazyRoute {...props} component={import('../403')} />,
  }, {
    breadcrumbName: '404/',
    path: '*',
    render: props => <LazyRoute {...props} component={import('../404')} />,
  }];

export default routers;

