import DateUtil from '../../../utils/DateUtil';

const { Record } = require('immutable');

const InitialState = Record({
  isFetching: false,
  page: 1,
  list: [],
  total: 0,
  error: null,

  startDate: DateUtil.day(DateUtil.getLatestMonthStart()),
  endDate: DateUtil.day(new Date()),
  province: '',
  cityId: '',
  country: '',
  options: [],
  resourceList: [],
  resourceId: '',
});

export default InitialState;

