const { Record } = require('immutable');

const InitialState = Record({
  autoBrands: [],
  autoSeries: [],
  autoTypes: [],

  autoBrandId: '',
  autoSeriesId: '请选择',
  autoTypeId: '请选择',

  productsList: [],
  salesList: [],
  companyList: [],
  teamList: [],
  userList: [],
});

export default InitialState;

