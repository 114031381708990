const InitialState = require('./orderInitialState').default;

const {
  SET_ORDER_KEY,

  SET_ORDER_STATUS,
  SET_ORDER_BIZTYPE,

  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,

  SET_ORDERS_PAGE,
} = require('../../constants').default;

const initialState = new InitialState;

export default function activityReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return state.set('isFetching', true);
    case GET_ORDERS_SUCCESS:
      const { list, total } = action.payload;
      return state.set('isFetching', false).set('list', list).set('total', parseInt(total, 10));
    case GET_ORDERS_FAILURE:
      return state.set('isFetching', false);

    case SET_ORDER_KEY:
      return state.set('searchKey', action.payload).set('page', 1);
    case SET_ORDER_STATUS:
      return state.set('status', action.payload).set('page', 1);
    case SET_ORDER_BIZTYPE:
      return state.set('bizType', action.payload).set('page', 1);

    case SET_ORDERS_PAGE:
      return state.set('page', action.payload);
  }

  return state;
}
