import React, { Component } from 'react';
import { Col, message, Modal, Popover, Row, Spin } from 'antd';
import QRCode from 'qrcode.react';

import apiDaotian from '../../configs/api-daotian';

import server from '../../utils/server';
import AuthUtil from '../../utils/AuthUtil';

import ApplyToUse from './ApplyToUse';
import DownloadGoogle from '../../components/DownloadGoogle';

require('../../styles/login.less');

const logo = require('../../images/login/daotian_logo.png');
const topLogo = require('../../images/login/top_logo.png');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code_id: '',
      btn_value: '获取验证码',
      isDisabled: false,
      isVisible: false,
      opacity: 1,
      loginId: '',
      isLoginExpire: false,
    };

    this.getVerifyCode = this.getVerifyCode.bind(this);
  }

  componentDidMount() {
    if (AuthUtil.isLogin()) {
      location.href = '/';
    } else {
      this.getLoginId();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://s19.cnzz.com/z_stat.php?id=1262229427&web_id=1262229427';
      document.body.appendChild(script);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interVal);
  }

  handleRefresh() {
    this.getLoginId();
    clearInterval(this.interVal);
  }

  getVerifyCode() {
    const phone = this.props.form.getFieldValue('phone');
    if (!phone) {
      message.error('请输入电话号码');
      return false;
    }

    let num = 10;
    this.setState({ isDisabled: 'disable', opacity: 0.5 });

    let btn_value = `${num}s`;
    this.setState({ btn_value });

    let time = setInterval(() => {
      num--;
      btn_value = `${num}s`;
      this.setState({ btn_value });

      if (num === 0) {
        this.setState({
          isDisabled: false,
          opacity: 1,
          btn_value: '获取验证码',
        });
        clearInterval(time);
        time = undefined;
      }
    }, 1000);

    server.post(apiDaotian.system.getVerifyCode(), {
      phone, permission: 'no-login',
    }, (data) => {
      message.info('验证码已发送', 3);
      const { sms } = data.res;
      if (sms && Object.keys(sms).length > 0) {
        this.setState({ code_id: sms._id });
      } else {
        message.error('验证码获取失败');
      }
    });
  }

  getLoginId() {
    server.get(apiDaotian.system.genLoginIId(), (data) => {
      this.setState({ loginId: data.res.login_id, isLoginExpire: false }, () => {
        try {
          this.interVal = setInterval(() => {
            if (this.interVal) {
              this.getLoginById(this.state.loginId);
            }
          }, 2000);
        } catch (e) {
          if (this.interVal) {
            clearInterval(this.interVal);
          }
          this.interVal = null;
          this.setState({
            isLoginExpire: false,
          });
        }

        const time = setTimeout(() => {
          this.setState({
            isLoginExpire: true,
          });
          clearTimeout(time);
          clearInterval(this.interVal);
        }, 1000 * 60);
      });
    });
  }

  getLoginById(loginId) {
    if (loginId) {
      server.get(apiDaotian.system.loginById(loginId), (data) => {
        window.accessToken = `Bearer ${data.res.access_token}`;
        localStorage.setItem('refressToken', `${data.res.refress_token}`);
        this.getUserInfo();
        clearInterval(this.interVal);
      }, () => {

      });
    }
  }

  getUserInfo() {
    // 1. 获取登录用户信息
    server.get(apiDaotian.system.userInfo(), (data) => {
      const { user } = data.res;
      if (user && Object.keys(user).length > 0) {
        user.uid = user._id;
        localStorage.setItem('first_login', '1');
        localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();
        localStorage.setItem('last_update', ((hour * 60) + minute).toString());
        localStorage.setItem('last_update_day', new Date().getDate().toString());
        message.success('登录成功');

        setTimeout(() => {
          location.href = '/';
        }, 500);
      } else {
        message.error('获取用户信息失败, 请重新登录');
      }
    });
  }

  render() {
    const { isVisible, loginId, isLoginExpire } = this.state;

    return (
      <div className="login-page content">
        <header>
          <div className="logo" style={{ marginTop: 25 }}>
            <img src={logo} alt="" />
          </div>
          <div className="phone">
            <span>寻求帮助: </span>
            <span>400-900-9556</span>
          </div>
          <div className="top-logo">
            <span>系统支持: </span>
            <img src={topLogo} alt="" />
          </div>
        </header>

        <section style={{ height: document.body.clientHeight - 165, minHeight: '550px' }}>
          <div className="section-content">
            <div className="word" style={{ marginTop: 250 }}>
              <div className="name">稻田智能门店管家</div>
              <div className="line" />
              <div className="slogan-one">一站式解决门店营销获客、运营管理、资金管理三大烦恼</div>
              {/* <div className="slogan-two">坚持耕耘持续进化的门店服务系统</div> */}
            </div>

            <div className="sign-in" style={{ marginTop: 160, marginLeft: 93, width: 376 }}>
              <div className="accountLogin">
                <p>账号登录</p>
              </div>
              <div className="flex-center">
                <div className="flex-center mt20 mb20" style={{ width: 270, color: '#666666' }}>
                  <span>
                    请使用
                    <Popover
                      placement="bottom"
                      title={null}
                      content={(
                        <div className="canvas no-print qr-popover-home">
                          <QRCode size={200} value={`${window.h5Host}/h5/app-download-tob.html`} />
                          <p className="padding-top-5">请用微信扫码下载</p>
                        </div>
                      )}
                      trigger="click"
                      overlayClassName="white"
                    >
                      <span style={{ color: '#4d94ff', cursor: 'pointer' }}>新版管家APP</span>
                    </Popover>
                    扫一扫登陆
                  </span>
                </div>
                {isLoginExpire ? (
                  <div className="refresh-login">
                    <img
                      style={{ width: 189, height: 185 }}
                      src={require('../../images/daotian/qrcode-expire.jpg')}
                    />

                    <div className="mask" onClick={() => this.handleRefresh()}>
                      <img
                        className="mt30"
                        style={{ width: 48, height: 48 }}
                        src={require('../../images/daotian/refresh.png')}
                      />
                      <div className="mt20">二维码已失效</div>
                    </div>
                  </div>
                ) : (
                  <Spin spinning={!loginId} tip="加载中...">
                    {loginId ? (
                      <QRCode
                        size={185}
                        value={JSON.stringify({
                          type: 'login',
                          data: { login_id: loginId },
                        })}
                      />
                    ) : (
                      <div style={{ width: 185, height: 185 }}>
                        <img
                          alt=""
                          style={{ width: 189, height: 185 }}
                          src={require('../../images/daotian/qrcode-expire.jpg')}
                        />
                      </div>
                    )}
                  </Spin>
                )}

                <Row>
                  <Col span={24}>
                    <div className="center mt5"><ApplyToUse /></div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Modal
            title="请联系店长，扫描小程序码完善门店资料"
            visible={isVisible}
            footer={null}
            width={420}
            onCancel={() => this.setState({ isVisible: false })}
          >
            <div style={{ textAlign: 'center' }}>
              <img
                src="https://shuidao-public-pic.qn.shuidao.com/qrcode_for_weapp_repair_me.png"
                style={{ width: 350, height: 350 }}
              />
            </div>
          </Modal>
        </section>

        <footer>
          <p>
            您正在使用稻田智能门店管家
            <a href="https://www.shuidao.com/desktop/">关于水稻</a>
            | <a href="https://www.shuidao.com/desktop/about.html">联系我们</a>
            | Copyright © 2012-{new Date().getFullYear()} 北京道胜科技有限公司. All Rights Reserved
            <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
              京ICP备15040810号
            </a>
          </p>
        </footer>

        <DownloadGoogle />
      </div>
    );
  }
}
export default Login;
