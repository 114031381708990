import keyMirror from 'key-mirror';

export default keyMirror({
  SET_PLATFORM: null,
  SET_VERSION: null,
  SET_APP_VERSION: null,

  SET_USER: null,
  SET_LOGIN: null,
  SET_IS_FIRST_LOGIN: null,

  SET_STATE: null,
  GET_STATE: null,
  SET_STORE: null,

  // 获取验证码
  GET_VERIFICATION_CODE: null,
  VERIFICATION_CODE_REQUEST: null,
  VERIFICATION_CODE_SUCCESS: null,
  VERIFICATION_CODE_FAILURE: null,

  // 登录
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  // 登录
  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  // 登录客户信息
  GET_CUSTOMER_REQUEST: null,
  GET_CUSTOMER_SUCCESS: null,
  GET_CUSTOMER_FAILURE: null,

  // 获取用户全部权限
  GET_USER_PERMISSIONS_SUCCESS: null,
  GET_USER_PERMISSIONS_FAILURE: null,

  // 保存到state
  SET_USER_PERMISSIONS: null,

  // 活动管理
  GET_ACTIVITIES_REQUEST: null,
  GET_ACTIVITIES_SUCCESS: null,
  GET_ACTIVITIES_FAILURE: null,

  ADD_ACTIVITY_REQUEST: null,
  ADD_ACTIVITY_SUCCESS: null,
  ADD_ACTIVITY_FAILURE: null,

  EDIT_ACTIVITY_REQUEST: null,
  EDIT_ACTIVITY_SUCCESS: null,
  EDIT_ACTIVITY_FAILURE: null,

  SET_ACTIVITY_PAGE: null,

  /**
   * 新车 订单管理
   * */
  // 意向管理
  SET_INTENTION_KEY: null,
  SET_INTENTION_BIZTYPE: null,

  GET_INTENTIONS_REQUEST: null,
  GET_INTENTIONS_SUCCESS: null,
  GET_INTENTIONS_FAILURE: null,

  SET_INTENTIONS_PAGE: null,

  // 订单管理
  SET_ORDER_KEY: null,
  SET_ORDER_STATUS: null,
  SET_ORDER_BIZTYPE: null,

  GET_ORDERS_REQUEST: null,
  GET_ORDERS_SUCCESS: null,
  GET_ORDERS_FAILURE: null,

  SET_ORDERS_PAGE: null,

  // 订单详情
  GET_ORDERS_DETAIL_REQUEST: null,
  GET_ORDERS_DETAIL_SUCCESS: null,

  GET_ORDERS_OUTCOLOR_REQUEST: null,
  GET_ORDERS_OUTCOLOR_SUCCESS: null,

  SET_ORDERS_APPLICATION_DOWNLOAD_INFO: null,
  SET_ORDERS_DEAL_DOWNLOAD_INFO: null,
  SET_REQUESTFUNDS_DOWNLOAD_INFO: null,
  SET_LOAN_DOWNLOAD_INFO: null,

  GET_ORDER_INSURANCE_COMPANY_REQUEST: null,
  GET_ORDER_INSURANCE_COMPANY_SUCCESS: null,

  SET_ORDER_INSRUANCE_DETAIL_MAP: null,

  GET_ORDER_INSURANCE_CONFIG_REQUEST: null,
  GET_ORDER_INSURANCE_CONFIG_SUCCESS: null,

  GET_INSURANCE_LOG_DETAIL_REQUEST: null,
  GET_INSURANCE_LOG_DETAIL_SUCCESS: null,

  GET_CUSTOMER_DETAIL_REQUEST: null,
  GET_CUSTOMER_DETAIL_SUCCESS: null,

  GET_PROFIT_DETAIL_REQUEST: null,
  GET_PROFIT_DETAIL_SUCCESS: null,

  GET_APPLICATION_MATERIAL_REQUEST: null,
  GET_APPLICATION_MATERIAL_SUCCESS: null,

  GET_PICKUP_MATERIAL_REQUEST: null,
  GET_PICKUP_MATERIAL_SUCCESS: null,

  GET_REQUEST_MATERIAL_REQUEST: null,
  GET_REQUEST_MATERIAL_SUCCESS: null,

  GET_LOAN_MATERIAL_REQUEST: null,
  GET_LOAN_MATERIAL_SUCCESS: null,

  GET_OPERATE_LOG_REQUEST: null,
  GET_OPERATE_LOG_SUCCESS: null,

  // 新建订单
  GET_NEW_AUTOBRANDS_REQUEST: null,
  GET_NEW_AUTOBRANDS_SUCCESS: null,

  GET_NEW_AUTOSERIES_REQUEST: null,
  GET_NEW_AUTOSERIES_SUCCESS: null,

  GET_NEW_AUTOTYPES_REQUEST: null,
  GET_NEW_AUTOTYPES_SUCCESS: null,

  GET_NEW_PROGRAMMES_REQUEST: null,
  GET_NEW_PROGRAMMES_SUCCESS: null,
  GET_NEW_PROGRAMMES_FAILURE: null,

  GET_NEW_TEAMS_REQUEST: null,
  GET_NEW_TEAMS_SUCCESS: null,

  GET_NEW_YOUCHE_USER_REQUEST: null,
  GET_NEW_YOUCHE_USER_SUCCESS: null,

  GET_SALES_LIST_REQUEST: null,
  GET_SALES_LIST_SUCCESS: null,

  GET_COMPANY_LIST_REQUEST: null,
  GET_COMPANY_LIST_SUCCESS: null,

  CREATE_ORDER_REQUEST: null,
  CREATE_ORDER_SUCCESS: null,

  SET_NEW_AUTO_BRANDID: null,
  SET_NEW_AUTO_SERIESID: null,
  SET_NEW_AUTO_TYPEID: null,

  // 新车问答管理
  GET_QA_REQUEST: null,
  GET_QA_SUCCESS: null,
  GET_QA_FAILURE: null,

  SET_QA_PAGE: null,

  // 材料配置
  GET_NEWCAR_MATERIAL_REQUEST: null,
  GET_NEWCAR_MATERIAL_SUCCESS: null,
  GET_NEWCAR_MATERIAL_FAILURE: null,

  SET_NEWCAR_MATERIAL_PAGE: null,

  // banner 管理
  GET_NEWCAR_BANNER_REQUEST: null,
  GET_NEWCAR_BANNER_SUCCESS: null,
  GET_NEWCAR_BANNER_FAILURE: null,

  SET_NEWCAR_BANNER_PAGE: null,

  // 资源方管理
  GET_NEWCAR_RESOURCE_REQUEST: null,
  GET_NEWCAR_RESOURCE_SUCCESS: null,
  GET_NEWCAR_RESOURCE_FAILURE: null,

  SET_NEWCAR_RESOURCE_PAGE: null,

  //  收益记录
  GET_NEWCAR_EARNINGSRECORD_REQUEST: null,
  GET_NEWCAR_EARNINGSRECORD_SUCCESS: null,
  GET_NEWCAR_EARNINGSRECORD_FAILURE: null,

  GET_NEWCAR_EARNINGSRECORD_REGIN_REQUEST: null,
  GET_NEWCAR_EARNINGSRECORD_REGIN_SUCCESS: null,

  GET_NEW_CAR_EARNINGS_PROVINCES_REQUEST: null,
  GET_NEW_CAR_EARNINGS_PROVINCES_SUCCESS: null,

  GET_NEWCAR_EARNINGS_RESOURCE_REQUEST: null,
  GET_NEWCAR_EARNINGS_RESOURCE_SUCCESS: null,

  SET_NEWCAR_PROVINCE: null,
  SET_NEWCAR_CITY: null,
  SET_NEWCAR_COUNTRY: null,

  SET_NEWCAR_EARNING_RESOURCEID: null,

  SET_NEWCAR_EARNINGS_START_DATE: null,
  SET_NEWCAR_EARNINGS_END_DATE: null,

  SET_NEWCAR_EARNINGSRECORD_PAGE: null,

  // 水稻汽车超市产品管理
  GET_MARKETLISTSUCCESS: null,
  GET_MARKETLISTREQUEST: null,
  POST_MARKERTPRODUCTCREATEREQUEST: null,
  POST_MARKERTPRODUCTCREATERESUCCESS: null,

  POST_MARKERTPRODUCT_EDIT_REQUEST: null,
  POST_MARKERTPRODUCT_EDIT_SUCCESS: null,

  POST_MARKERTPRODUCTRISKREQUEST: null,
  POST_MARKERTPRODUCTRISKSUCCESS: null,
  POST_MARKERTDITAMOUNTFIXFINANCEREQUEST: null,
  POST_MARKERTDITAMOUNTFIXFINANCESUCCESS: null,
  POST_MARKERTEDITLOANFINANCEREQUEST: null,
  POST_MARKERTEDITLOANFINANCESUCCESS: null,
  GET_MARKERTMATERIALLISTDATAREQUEST: null,
  GET_MARKERTMATERIALLISTDATASUCCESS: null,
  POST_MARKERTEDITMATERIALREQUESTREQUEST: null,
  POST_MARKERTEDITMATERIALREQUESTSUCCESS: null,

  GET_REGIN_REQUEST: null,
  GET_REGIN_SUCCESS: null,

  GET_PROVINCES_REQUEST: null,
  GET_PROVINCES_SUCCESS: null,

  // 水稻汽车超市运营管理
  GET_MARKERTPROALLLISTREQUEST: null,
  GET_MARKERTPROALLLISTSUCCESS: null,
  // 水稻汽车超市方案管理
  GET_BRANDSREQUESTREQUEST: null,
  GET_BRANDSREQUESTSUCCESS: null,
  GET_SERISEBYBRANDREQUEST: null,
  GET_SERISEBYBRANDSUCCESS: null,
  GET_TYPESBYSERIESREQUEST: null,
  GET_TYPESBYSERIESSUCCESS: null,
  POST_CREATELOANPLANREQUEST: null,
  POST_CREATELOANPLANSUCCESS: null,
  POST_CREATEAMOUNTFIXPLANREQUEST: null,
  POST_CREATEAMOUNTFIXPLANSUCCESS: null,

  GET_PROGRAME_REGIN_REQUEST: null,
  GET_PROGRAME_REGIN_SUCCESS: null,

  GET_PROGRAME_PROVINCES_REQUEST: null,
  GET_PROGRAME_PROVINCES_SUCCESS: null,

  GET_MARKERTPLANALLLISTREQUEST: null,
  GET_MARKERTPLANALLLISTSUCCESS: null,
  POST_MARKERTRESOURCECREATEREQUEST: null,
  POST_MARKERTRESOURCECREATESUCCESS: null,
  POST_MARKERTPRODUCTOFFLINEREQUEST: null,
  POST_MARKERTPRODUCTOFFLINESUCCESS: null,
  POST_MARKERTPRODUCTONLINEREQUEST: null,
  POST_MARKERTPRODUCTONLINESUCCESS: null,
  POST_MARKETPLANEDITHOTREQUEST: null,
  POST_MARKETPLANEDITHOTSUCCESS: null,
  POST_MARKETPLANOFFLINEREQUEST: null,
  POST_MARKETPLANOFFLINESUCCESS: null,
  POST_MARKETPLANONLINEREQUEST: null,
  POST_MARKETPLANONLINESUCCESS: null,
  GET_PRODUCTDETAILREQUEST: null,
  GET_PRODUCTDETAILSUCCESS: null,
  GET_PRODUCTLISTALLREQUEST: null,
  GET_PRODUCTLISTALLSUCCESS: null,

  GET_PRODUCT_DETAIL_REQUEST: null,
  GET_PRODUCT_DETAIL_SUCCESS: null,

  SET_CARPRO_PAGE: null,
  SET_FINANCIALPRO_PAGE: null,

  GET_PLANDETAILREQUEST: null,
  GET_PLANDETAILSUCCESS: null,
  POST_EDITAMOUNTFIXPLANREQUEST: null,
  POST_EDITAMOUNTFIXPLANSUCCESS: null,
  POST_EDITLOANPLANREQUEST: null,
  POST_EDITLOANPLANSUCCESS: null,

  SET_VEHICLESCHEME_PAGE: null,
  SET_FINANCIALPROGRAM_PAGE: null,

  POST_CREATEAUTOTYPEREQUEST: null,
  POST_CREATEAUTOTYPESUCCESS: null,

  GET_STATISTIC_LIST_REQUEST: null,
  GET_STATISTIC_LIST_SUCCESS: null,

  EDIT_STATISTIC_REQUEST: null,
  EDIT_STATISTIC_SUCCESS: null,

  CREATE_STATISTIC_REQUEST: null,
  CREATE_STATISTIC_SUCCESS: null,

  SET_MONTH: null,
  SET_CITY: null,

  // 车型配置
  GET_MODELS_LIST_REQUEST: null,
  GET_MODELS_LIST_SUCCESS: null,
  GET_MODELS_LIST_FAILURE: null,

  CREATE_MODEL_REQUEST: null,
  CREATE_MODEL_SUCCESS: null,

  EDIT_MODEL_REQUEST: null,
  EDIT_MODEL_SUCCESS: null,

  GET_MODELS_AUTO_DETAIL_REQUEST: null,
  GET_MODELS_AUTO_DETAIL_SUCCESS: null,

  GET_MODELS_AUTOBRANDS_REQUEST: null,
  GET_MODELS_AUTOBRANDS_SUCCESS: null,

  GET_MODELS_AUTOSERIES_REQUEST: null,
  GET_MODELS_AUTOSERIES_SUCCESS: null,

  GET_MODELS_AUTOTYPES_REQUEST: null,
  GET_MODELS_AUTOTYPES_SUCCESS: null,

  SET_MODELS_PAGE: null,

  SET_MODELS_AUTO_BRANDID: null,
  SET_MODELS_AUTO_SERIESID: null,
  SET_MODELS_AUTO_TYPEID: null,

  SET_MODELS_GUIDE_PRICE: null,
  SET_MODELS_CUSTOME_AUTO: null,

  // 风控团队通知
  GET_NOTICE_LIST_REQUEST: null,
  GET_NOTICE_LIST_SUCCESS: null,
});

