import { message } from 'antd';
import NProgress from 'nprogress';
import 'isomorphic-fetch';
import bugsnag from '@bugsnag/js';

import apiAdmin from '../configs/api-admin';
import apiChengshi from '../configs/api-chengshi';
import apiDaotian from '../configs/api-daotian';
import apiChain from '../configs/api-chain';
import apiDealer from '../configs/api-dealer';
import apiGuanChe from '../configs/api-guanche';

import AuthUtil from './AuthUtil';

function filterJSON(response) {
  if (response.ok) {
    return response.json();
  }

  message.error('网络请求失败');
}

function filterBlob(response) {
  if (response.ok) {
    return { blob: response.blob(), filename: response.headers.get('content-disposition') };
  }

  message.error('网络请求失败');
}

function downLoad(fileName, blobURl) {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = blobURl;
  a.click();
}

function updateURLArgs(url, arg, argVal) {
  const pattern = `${arg}=([^&]*)`;
  const replaceText = `${arg}=${argVal}`;
  if (url.match(pattern)) {
    let tmp = `/(${arg}=)([^&]*)/gi`;
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  }
  if (url.match('[\?]')) {
    return `${url}&${replaceText}`;
  }
  return `${url}?${replaceText}`;
}

const BugsnagClient = bugsnag('df5c804812af2b8a4c376c5cc5478559');

let requestUrls = [];
let refressTimer = null;
const server = {
  // 业务模块用于 判断 admin,chengshi,daotian,dealer
  businessModule: '',
  // TODO 过度方法，后续使用get,post代替
  /* ajax(options, successCallback, errorCallback) {
        if (!window.navigator.onLine) {
          message.warning('已断网，请检查网络连接');
          return;
        }

        if (options.permission !== 'no-login' && !AuthUtil.isLogin()) {
          location.href = '/login';
          return;
        }

        if (typeof options !== 'object') {
          return;
        }

        NProgress.start(); // show top progress bar

        $.ajax({
          url: options.url,
          type: options.type || 'GET',
          header: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
          },
          xhrFields: {
            withCredentials: true,
          },
          dataType: 'json',
          data: options.data,
          success(data) {
            NProgress.done(); // cancel top progress bar

            if (data.code !== 0) {
              if (data.code === 1001) { // 未登录
                localStorage.clear();
                setTimeout(() => {
                  location.reload();
                }, 0); // todo
              } else {
                if (typeof (errorCallback) === 'function') {
                  errorCallback(data.msg, data);
                }
                message.error(data.msg);
              }
            } else {
              typeof successCallback === 'function' && successCallback(data);
            }
          },
          error(error) {
            NProgress.done();
            typeof errorCallback === 'function' ? errorCallback(error) : message.error(error);
          },
        });
      }, */

  // TODO [get | post] 自定义封装get和post方法，做容错处理后应用全局

  get(url, successCallback, errorCallback) {
    NProgress.start(); // show top progress bar
    url = updateURLArgs(url, 'rtick', new Date().getTime());
    // chengshi,daotian 不检查 login-by-id, gen-login-id接口
    if (!window.accessToken && url.indexOf('/gen-login-id') === -1 && url.indexOf('login-by-id') === -1) {
      requestUrls.push({ url, method: 'get', successCallback, errorCallback });
      refressLogin();
      return;
    }
    const timeout = new Promise((resolve, reject) => setTimeout(() => reject(new Error('请求超时，请稍后重试')), 60 * 1000));
    const requestFetch = new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Authorization: window.accessToken || '',
        },
        credentials: 'include',
      }).then(filterJSON)
        .then((data) => {
          NProgress.done();
          clearTimeout(timeout);
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
    });

    Promise.race([timeout, requestFetch]).then((data) => {
      if (data && data.code !== 0) {
        if (data.code === 1001) { // 未登录
          requestUrls.push({ url, method: 'get', successCallback, errorCallback });
          refressLogin();
        } else {
          // eslint-disable-next-line no-unused-expressions
          typeof (errorCallback) === 'function'
            ? errorCallback(data.msg)
            : message.error(data.msg);
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        typeof successCallback === 'function' && successCallback(data);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-unused-expressions
      typeof (errorCallback) === 'function'
        ? errorCallback(error.message)
        : message.error(error.message);

      BugsnagClient.notify(error);
    });
  },

  downloadFile(url, successCallback, errorCallback) {
    NProgress.start(); // show top progress bar
    url = updateURLArgs(url, 'rtick', new Date().getTime());
    if (!window.accessToken) {
      requestUrls.push({ url, method: 'downloadFile', successCallback, errorCallback });
      refressLogin();
      return;
    }

    const timeout = new Promise((resolve, reject) => setTimeout(() => reject(new Error('请求超时，请稍后重试')), 60 * 1000));
    const requestFetch = new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Authorization: window.accessToken || '',
        },
      }).then(filterBlob)
        .then((data) => {
          const filename = data.filename.split(';')[1].split('filename=')[1];
          data.blob.then(blob => resolve({ blob, filename: filename.replace(/"/g, '') }));
          NProgress.done();
          clearTimeout(timeout);
        }).catch((error) => {
          reject(error);
        });
    });

    Promise.race([timeout, requestFetch]).then((data) => {
      if (data && data.code !== 0 && data.code !== undefined) {
        if (data.code === 1001) { // 未登录
          requestUrls.push({ url, method: 'downloadFile', successCallback, errorCallback });
          refressLogin();
        } else {
          // eslint-disable-next-line no-unused-expressions
          typeof (errorCallback) === 'function'
            ? errorCallback(data.msg)
            : message.error(data.msg);
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        typeof successCallback === 'function' && successCallback(data);
        downLoad(data.filename, window.URL.createObjectURL(data.blob));
      }
    }).catch((error) => {
      // eslint-disable-next-line no-unused-expressions
      typeof (errorCallback) === 'function'
        ? errorCallback(error.message)
        : message.error(error.message);

      BugsnagClient.notify(error);
    });
  },

  post(url, data, successCallback, errorCallback) {
    if (!window.navigator.onLine) {
      message.warning('已断网，请检查网络连接');
      return;
    }

    if (data.permission !== 'no-login' && !AuthUtil.isLogin()) {
      location.href = '/login';
      return;
    }

    if (!window.accessToken && data.permission !== 'no-login') {
      requestUrls.push({ url, method: 'post', data, successCallback, errorCallback });
      refressLogin();
      return;
    }

    if (data.permission) {
      delete data.permission;
    }

    NProgress.start(); // show top progress bar
    const fd = new FormData();
    url = updateURLArgs(url, 'rtick', new Date().getTime());
    // eslint-disable-next-line no-restricted-syntax
    for (const propName in data) {
      if (data[propName] === undefined) {
        fd.append([propName], '');
      } else {
        fd.append([propName], data[propName]);
      }
    }
    const timeout = new Promise((resolve, reject) => setTimeout(() => reject(new Error('请求超时，请稍后重试')), 120 * 1000));
    const requestFetch = new Promise((resolve, reject) => {
      fetch(url, {
        method: 'post',
        body: fd,
        credentials: 'include',
        mode: 'cors',
        headers: {
          Authorization: window.accessToken || '',
        },
      })
        .then(filterJSON)
        .then((response) => {
          clearTimeout(timeout);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });

    Promise.race([timeout, requestFetch]).then((response) => {
      NProgress.done();
      if (response && response.code !== 0) {
        if (response.code === 1001) { // 未登录
          if (url.indexOf('refress-login') > -1) {
            requestUrls = [];
            window.accessToken = '';
            Object.keys(localStorage).forEach((key) => {
              if (key !== 'is_show_print_tip' && key !== 'is_show_enterprise_tip') {
                localStorage.removeItem(key);
              }
            });
            message.error('请重新登陆');
            location.href = '/login';
            return;
          }
          requestUrls.push({ url, method: 'post', data, successCallback, errorCallback });
          refressLogin();
        } else {
          // eslint-disable-next-line no-unused-expressions
          typeof (errorCallback) === 'function'
            ? errorCallback(response.msg, response.code)
            : message.error(response.msg);
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        typeof successCallback === 'function' && successCallback(response);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-unused-expressions
      typeof errorCallback === 'function' ? errorCallback(error) : message.error(error);
      BugsnagClient.notify(error);
    });
  },
};

function refressLogin() {
  const refressToken = localStorage.getItem('refressToken');
  if (refressToken) {
    if (refressTimer) {
      clearTimeout(refressTimer);
    }
    let serverApi = apiAdmin.system.refressLogin();
    if (server.businessModule === 'chengshi') {
      serverApi = apiChengshi.system.refressLogin();
    } else if (server.businessModule === 'daotian') {
      serverApi = apiDaotian.system.refressLogin();
    } else if (server.businessModule === 'chain') {
      serverApi = apiChain.system.refressLogin();
    } else if (server.businessModule === 'dealer') {
      serverApi = apiDealer.system.refressLogin();
    } else if (server.businessModule === 'guanche') {
      serverApi = apiGuanChe.system.refressLogin();
    }
    refressTimer = setTimeout(() => {
      server.post(serverApi,
        {
          refress_token: refressToken,
          permission: 'no-login',
          rtick: new Date().getTime(),
        }, (refressData) => {
          window.accessToken = `Bearer ${refressData.res.access_token}`;
          requestUrls.forEach((item) => {
            if (item.method === 'downloadFile') {
              server.downloadFile(item.url, item.successCallback, item.errorCallback);
            } else if (item.method === 'get') {
              server.get(item.url, item.successCallback, item.errorCallback);
            } else if (item.method === 'post') {
              server.post(item.url, item.data, item.successCallback, item.errorCallback);
            }
          });
          requestUrls = [];
          clearTimeout(refressTimer);
        }, () => {
          window.accessToken = '';
          requestUrls = [];
          clearTimeout(refressTimer);
          Object.keys(localStorage).forEach((key) => {
            if (key !== 'is_show_print_tip' && key !== 'is_show_enterprise_tip') {
              localStorage.removeItem(key);
            }
          });
          location.href = '/login';
        });
    }, 600);
    return;
  }
  Object.keys(localStorage).forEach((key) => {
    if (key !== 'is_show_print_tip' && key !== 'is_show_enterprise_tip') {
      localStorage.removeItem(key);
    }
  });
  window.accessToken = '';
  requestUrls = [];
  location.href = '/login';
}

export default server;
