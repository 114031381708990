import apiDaotian from './api-daotian';

import server from '../utils/server';
import validator from '../utils/validator';
import AuthUtil from '../utils/AuthUtil';

const hrefBack = location.href;

const whiteList = ['app/download'];       // 白名单
let inWhiteList = false;                  // 是否在白名单，在白名单则请求用户状态
for (const key of whiteList) {
  if (hrefBack.indexOf(key) >= 0) {
    inWhiteList = true;
    break;
  }
}

if (!inWhiteList) {
  // 防止localStorage预存信息不是base64报错
  const USER_SESSION = localStorage.getItem('USER_SESSION');
  const base64Reg = validator.pattern.base64;
  const isbase64 = base64Reg.test(USER_SESSION);
  if (!isbase64) {
    localStorage.clear();
  }

  if (AuthUtil.isLogin()) {
    // 如果用户信息为空，则获取用户信息
    if (Object.keys(AuthUtil.getLoginUserInfo()).length === 0) {
      server.get(apiDaotian.system.userInfo(), (data) => {
        const { user } = data.res;
        localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
      });
    }

    // 如果用户登录的门店信息为空，则获取用
    if (Object.keys(AuthUtil.getLoginUserStoreInfo()).length === 0) {
      server.get(apiDaotian.company.detail(), (data) => {
        localStorage.setItem('STORE_INFO', window.btoa(window.encodeURIComponent(JSON.stringify(data.res.company))));
      });
    }
  }
}
