import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import activity from './activity/activityReducer';
// 新车
import intention from './youche/order/intentionReducer';
import order from './youche/order/orderReducer';
import orderDetail from './youche/order/detailReducer';

import orderNew from './youche/order/newReducer';

import newCarQa from './youche/qa/qaReducer';
import newCarBanner from './youche/banner/bannerReducer';
import newCarResource from './youche/resource/resourceReducer';
import newCarMaterial from './youche/material/materialReducer';

import newCarEarningsRecord from './youche/earnings-record/earningsRecordReducer';
import newCarModels from './auto-models/modelsReducer';

import notice from './notice/noticeReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const rootReducer = combineReducers({
  routing,

  auth,
  activity,

  intention,
  order,
  orderDetail,
  orderNew,

  newCarQa,
  newCarBanner,
  newCarResource,
  newCarEarningsRecord,
  newCarMaterial,

  newCarModels,

  notice,
});

export default rootReducer;
